import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ISelectOption } from '~/core/@types/global'
import { ROLE } from '~/core/constants/role'
import { LockIcon } from '~/core/ui/FillIcons'
import IconWrapper from '~/core/ui/IconWrapper'
import If from '~/core/ui/If'
import { SuggestionChips } from '~/core/ui/SuggestionChips'
import { TypographyText } from '~/core/ui/Text'
import { Tooltip } from '~/core/ui/Tooltip'
import { getYears } from '~/core/utilities/common'
import { adminCanAction } from '~/core/utilities/permission'
import { ICandidateProfile } from '~/lib/features/candidates/types'
import { permittedFieldsManagement } from '~/lib/features/candidates/utilities'
import { totalYoeOptions } from '~/lib/features/candidates/utilities/enum'
import useBoundStore from '~/lib/store'

const ProfileInformationViewOnly: FC<{
  data: ICandidateProfile

  configHide?: {
    [key: string]: boolean
  }
}> = ({ data, configHide = {} }) => {
  const { t } = useTranslation()

  const { user, currentRole } = useBoundStore()
  const permittedFields = data?.permittedFields
  const skillString = JSON.parse(
    JSON.stringify(permittedFields?.skills?.value || [])
  )

  const suggestionChipSkill = skillString.map((item: string) => {
    return {
      label: item,
      maxLength: 30
    }
  })

  return (
    <div className="grid  desktop:grid-cols-[160px_1fr]">
      {!configHide.totalYearsOfExp ? (
        <>
          <If
            condition={permittedFieldsManagement(
              permittedFields?.totalYearsOfExp?.roles
            )}>
            <div className="flex items-center space-x-2">
              <IconWrapper
                size={16}
                name="Boxes"
                className="hidden text-gray-600 desktop:block"
              />
              <TypographyText className="text-sm text-gray-700">
                {t(
                  'candidates:tabs:candidateOverview:profileInformation:totalYoe'
                )}
              </TypographyText>
            </div>

            <div className="w-full">
              <div className="flex items-center px-2 py-1.5 text-sm">
                {['number', 'string'].includes(
                  typeof data?.permittedFields?.totalYearsOfExp?.value
                ) ? (
                  <div className="text-gray-900">
                    {
                      totalYoeOptions
                        .map((item) => ({
                          ...item,
                          supportingObj: {
                            name: `${t(`candidates:yoeOptions:${item.value}`)}`
                          }
                        }))
                        .find(
                          (item) =>
                            item.value ===
                            data?.permittedFields?.totalYearsOfExp?.value?.toString()
                        )?.supportingObj?.name
                    }
                  </div>
                ) : (
                  <div className="text-gray-600">
                    {t('candidates:tabs:candidateOverview:notAvailable')}
                  </div>
                )}
              </div>
            </div>
          </If>
        </>
      ) : null}

      {!configHide.skills ? (
        <>
          <If
            condition={permittedFieldsManagement(
              permittedFields?.skills?.roles
            )}>
            <div>
              <div className="flex w-full items-center space-x-2 py-1.5">
                <IconWrapper
                  size={16}
                  name="ListChecks"
                  className="hidden text-gray-600 desktop:block"
                />
                <TypographyText className="text-sm text-gray-700">
                  {t(
                    'candidates:tabs:candidateOverview:profileInformation:skills'
                  )}
                </TypographyText>
                <If
                  condition={
                    adminCanAction(currentRole?.code) &&
                    !permittedFields?.skills?.roles?.includes(ROLE.Everyone)
                  }>
                  <Tooltip
                    classNameConfig={{ content: 'max-w-full' }}
                    content={t('candidates:tabs:candidateOverview:visibleTo', {
                      role: permittedFields?.skills?.roles?.join(', ')
                    })}>
                    <LockIcon />
                  </Tooltip>
                </If>
              </div>
            </div>

            <div className="w-full">
              <div className="flex items-center px-2 py-1.5 text-sm">
                {data?.permittedFields?.skills?.value &&
                data?.permittedFields?.skills?.value?.length > 0 ? (
                  <div className="text-gray-900">
                    <SuggestionChips
                      size="sm"
                      source={suggestionChipSkill}
                      type="default"
                    />
                  </div>
                ) : (
                  <div className="text-gray-600">
                    {t('candidates:tabs:candidateOverview:notAvailable')}
                  </div>
                )}
              </div>
            </div>
          </If>
        </>
      ) : null}

      {!configHide.language ? (
        <>
          <If
            condition={permittedFieldsManagement(
              permittedFields?.languages?.roles
            )}>
            <div>
              <div className="flex w-full items-center space-x-2 py-1.5">
                <IconWrapper
                  size={16}
                  name="Languages"
                  className="hidden text-gray-600 desktop:block"
                />
                <TypographyText className="text-sm text-gray-700">
                  {t(
                    'candidates:tabs:candidateOverview:profileInformation:languages'
                  )}
                </TypographyText>
                <If
                  condition={
                    adminCanAction(currentRole?.code) &&
                    !permittedFields?.languages?.roles?.includes(ROLE.Everyone)
                  }>
                  <Tooltip
                    classNameConfig={{ content: 'max-w-full' }}
                    content={t('candidates:tabs:candidateOverview:visibleTo', {
                      role: permittedFields?.languages?.roles?.join(', ')
                    })}>
                    <LockIcon />
                  </Tooltip>
                </If>
              </div>
            </div>

            <div className="px-2 py-1.5">
              {data?.permittedFields?.languages?.value &&
                data?.permittedFields?.languages?.value?.length > 0 &&
                data?.permittedFields?.languages?.value?.map((item, index) => {
                  return (
                    <div className="group" key={index}>
                      <div className="flex items-center">
                        <div className="mr-1 text-sm text-gray-900">
                          {item.languageDescription}
                        </div>
                        {item.proficiencyDescription && (
                          <div className="text-sm text-gray-600">
                            ({item.proficiencyDescription})
                          </div>
                        )}
                      </div>
                    </div>
                  )
                })}
            </div>
          </If>
        </>
      ) : null}

      {!configHide.birthday ? (
        <>
          <If
            condition={permittedFieldsManagement(
              permittedFields?.birthday?.roles
            )}>
            <div className="flex items-center space-x-2">
              <IconWrapper
                size={16}
                name="Cake"
                className="hidden text-gray-600 desktop:block"
              />
              <TypographyText className="text-sm text-gray-700">
                {t(
                  'candidates:tabs:candidateOverview:profileInformation:birthday'
                )}
              </TypographyText>
            </div>
            <div className="w-full">
              <div className="flex items-center px-2 py-1.5 text-sm">
                {permittedFields?.birthday?.value ? (
                  <div className="text-gray-900">
                    {
                      getYears().filter(
                        (item: ISelectOption) =>
                          item.value ===
                          Number(
                            permittedFields?.birthday?.value?.birth_year
                          ).toString()
                      )[0]?.supportingObj?.name
                    }
                  </div>
                ) : (
                  <div className="text-gray-600">
                    {t('candidates:tabs:candidateOverview:notAvailable')}
                  </div>
                )}
              </div>
            </div>
          </If>
        </>
      ) : null}

      {!configHide.nationality ? (
        <>
          <If
            condition={permittedFieldsManagement(
              permittedFields?.nationality?.roles
            )}>
            <div className="flex w-full items-center space-x-2">
              <IconWrapper
                size={16}
                name="Flag"
                className="hidden text-gray-600 desktop:block"
              />
              <TypographyText className="text-sm text-gray-700">
                {t(
                  'candidates:tabs:candidateOverview:profileInformation:nationality'
                )}
              </TypographyText>
              <If
                condition={
                  adminCanAction(currentRole?.code) &&
                  !permittedFields?.nationality?.roles?.includes(ROLE.Everyone)
                }>
                <Tooltip
                  classNameConfig={{ content: 'max-w-full' }}
                  content={t('candidates:tabs:candidateOverview:visibleTo', {
                    role: permittedFields?.nationality?.roles?.join(', ')
                  })}>
                  <LockIcon />
                </Tooltip>
              </If>
            </div>

            <div className="w-full">
              <div className="flex items-center px-2 py-1.5 text-sm">
                {permittedFields?.nationality?.value ? (
                  <div className="text-gray-900">
                    {permittedFields?.nationality?.value}
                  </div>
                ) : (
                  <div className="text-gray-600">
                    {t('candidates:tabs:candidateOverview:notAvailable')}
                  </div>
                )}
              </div>
            </div>
          </If>
        </>
      ) : null}
    </div>
  )
}

export default ProfileInformationViewOnly
