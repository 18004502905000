import { useCallback, useEffect, useState } from 'react'
import useContextGraphQL from '~/core/middleware/use-context-graphQL'
import { catchErrorFromGraphQL } from '~/core/utilities/catch-api-error'
import QueryTenantReferralSettingShow from '../graphql/query-tenant-referral'
import { ReferralSettingType } from '../types'
import { create } from 'zustand'
import useDetectCompanyWithKind from '~/lib/hooks/use-detect-company-with-kind'
import { AGENCY_TENANT } from '~/core/constants/enum'
import QueryTenantReferralAgencySettingShow from '../graphql/query-tenant-referral-agency'
import { isInCareerHubApp } from '~/core/utilities/common'
import { useRouter } from 'next/router'
import QueryTenantReferralCHubSettingShow from '~/lib/features/career-hub/graphql/query-tenant-referral-chub'

const useDataReferralSetting = create<{
  dataReferral?: ReferralSettingType
  setDataReferral: (data: ReferralSettingType) => void
}>((set) => ({
  setDataReferral: (data: ReferralSettingType) =>
    set((state) => ({ ...state, dataReferral: data }))
}))

const useReferralSetting = ({ suspend }: { suspend?: boolean } = {}) => {
  const router = useRouter()
  const { clientGraphQL } = useContextGraphQL()
  const { dataReferral, setDataReferral } = useDataReferralSetting()
  const { isCompanyKind: isAgencyCompany, isLoaded } = useDetectCompanyWithKind(
    {
      kind: AGENCY_TENANT
    }
  )
  const fetchReferralSettingShow = useCallback(() => {
    return clientGraphQL
      .query(
        isInCareerHubApp(router.asPath)
          ? QueryTenantReferralCHubSettingShow
          : isAgencyCompany
          ? QueryTenantReferralAgencySettingShow
          : QueryTenantReferralSettingShow
      )
      .then(
        (result: {
          error: { graphQLErrors: Array<object> }
          data: { tenantReferralSettingShow: ReferralSettingType }
        }) => {
          if (result.error) {
            return catchErrorFromGraphQL({
              error: result.error,
              error404ShouldForceToNotFoundPage: true
            })
          }

          const { tenantReferralSettingShow } = result.data || []
          setDataReferral(tenantReferralSettingShow)
          return
        }
      )
  }, [clientGraphQL, isAgencyCompany])

  useEffect(() => {
    !suspend && isLoaded && fetchReferralSettingShow()
  }, [suspend, isLoaded])

  return { dataReferral, fetchReferralSettingShow }
}
export default useReferralSetting
