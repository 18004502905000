import { gql } from 'urql'
import { ICandidateApplicant } from '../types'

const QueryCandidateApplicantFeedbackDetail = gql<
  { applicantsShow: ICandidateApplicant },
  {
    id: number
    jobIkitEvalId?: number
  }
>`
  query ($id: Int!, $jobIkitEvalId: Int) {
    applicantsShow(id: $id, jobIkitEvalId: $jobIkitEvalId) {
      id
      createdAt
      jobStage {
        stageTypeId
        stageLabel
        stageGroup
      }
      job {
        id
        title
        company {
          contacts {
            firstName
            lastName
            email
          }
        }
        owner {
          id
        }
        jobRecruiters {
          id
          user {
            id
          }
        }
      }
      pendingInterviewFeedbacks {
        id
      }
      jobIkitEvaluation {
        id
        name
        note
        overallFeedback
        status
        jobStages {
          stageTypeId
          stageLabel
        }
        user {
          id
          defaultColour
          fullName
          avatarVariants
        }
      }
      profile {
        id
        fullName
        email
        headline
        phoneNumber
        address
        avatarVariants
        coverLetter
        links
        sourced
        sourcedDescription
        sourcedName
        sourcedNameDescription
        createdAt
        profileCvs {
          id
          attachments {
            id
            file
            blobs
          }
        }
        jobs {
          id
          title
        }
        applicants {
          id
          coverLetter
          job {
            status
            id
            title
            slug
            owner {
              id
              fullName
              email
            }
          }
        }
        applicableJobs {
          id
          title
          jobLocations {
            state
            country
          }
          owner {
            id
            fullName
            email
          }
          department {
            name
          }
        }
        user {
          id
          fullName
          avatarVariants
          defaultColour
        }
        owner {
          id
          fullName
          avatarVariants
          defaultColour
        }
        totalYearsOfExp
        permittedFields
        tags {
          value
          name
          id
        }
        warning
        defaultAccessibleApplicantId
      }
      placement {
        id
        status
        hiredDate
        onboardDate
        endOfProbationDate
        salary
        typeOfSalary
        currencyOfSalary
        fee
        typeOfFee
        revenue
        currencyOfRevenue
        applicant {
          id
          createdAt
          hiredDate
          jobStage {
            id
          }
          job {
            id
            title
          }
          profile {
            fullName
          }
        }
        hiredBy {
          id
          fullName
        }
        profitSplits {
          id
          profitPercentage
          user {
            id
            fullName
            avatarVariants
            defaultColour
          }
        }
        company {
          id
          name
        }
      }
    }
  }
`

export default QueryCandidateApplicantFeedbackDetail
