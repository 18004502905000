import { IUserInformation } from '~/core/@types/global'

export const mappingUserCookie = (user?: IUserInformation) => {
  if (!user) return {}

  return {
    id: user.id,
    email: user.email,
    fullName: user.fullName,
    phoneNumber: user.phoneNumber,
    jobTitle: user.jobTitle,
    provider: user.provider,
    language: user.language,
    timezone: user.timezone,
    avatarVariants: user.avatarVariants,
    defaultColour: user.defaultColour,
    userTenants: user?.userTenants,
    ownTenant: user.ownTenant
  }
}
