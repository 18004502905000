import { differenceInCalendarDays } from 'date-fns'
import { TaskItemType, TaskListGrouping } from '../types'

export const mappingTaskList = (
  pages: Array<{ tasksList: { collection: Array<TaskItemType> } }>
) => {
  return pages
    .map((page) =>
      page.tasksList?.collection?.reduce<TaskListGrouping>(
        (groupingData, task) => {
          const diffInDays = task.dueDate
            ? differenceInCalendarDays(new Date(task.dueDate), new Date())
            : undefined

          if (diffInDays === undefined || diffInDays >= 2) {
            groupingData['upcoming'].push(task)
          } else if (diffInDays === 1) {
            groupingData['tomorrow'].push(task)
          } else if (diffInDays <= -2) {
            groupingData['overdue'].push(task)
          } else {
            groupingData['today'].push(task)
          }

          return groupingData
        },
        { today: [], tomorrow: [], upcoming: [], overdue: [] }
      )
    )
    .reduce<TaskListGrouping>(
      (mergeData, groupingData) => {
        return {
          upcoming: [...mergeData.upcoming, ...(groupingData?.upcoming || [])],
          today: [...mergeData.today, ...(groupingData?.today || [])],
          overdue: [...mergeData.overdue, ...(groupingData?.overdue || [])],
          tomorrow: [...mergeData.tomorrow, ...(groupingData?.tomorrow || [])]
        }
      },
      {
        today: [],
        tomorrow: [],
        upcoming: [],
        overdue: []
      }
    )
}
