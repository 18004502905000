import { FC } from 'react'
import { Skeleton } from '~/core/ui/Skeleton'

const JobDetailSkeleton: FC = () => (
  <>
    {[1, 2].map((item) => (
      <div className="mb-1.5 last:mb-0" key={`job-detail-skeleton-${item}`}>
        <Skeleton className="mb-1.5 h-5 w-full rounded last:mb-0" />
        <Skeleton className="mb-1.5 h-5 w-full rounded last:mb-0" />
        <Skeleton className="mb-1.5 h-5 w-full rounded last:mb-0" />
      </div>
    ))}
  </>
)

export default JobDetailSkeleton
