import configuration from '~/configuration'
import { IPromiseSearchOption } from '~/core/@types/global'
import useContextGraphQL, {
  IResponseContextResult
} from '~/core/middleware/use-context-graphQL'
import QueryTenantMembers from '../graphql/query-tenant-members'
import { IMember } from '../types'

const usePromiseOwnerOptionsFetchAll = () => {
  const { clientGraphQL } = useContextGraphQL()
  const promiseMembersOwnerOptionsFetchAll = (
    params = {} as IPromiseSearchOption
  ) =>
    new Promise<any>((resolve) => {
      clientGraphQL
        .query(QueryTenantMembers, {
          ...params
        })
        .toPromise()
        .then((result: IResponseContextResult<IMember>) => {
          if (result.error) {
            resolve({
              metadata: {
                totalCount: configuration.defaultAsyncLoadingOptions
              },
              collection: []
            })
          }

          const { tenantMembers } = result.data
          const collection = tenantMembers?.collection || []
          const metadata = tenantMembers?.metadata || {}

          const cloneData = collection.map((item: IMember) => {
            return {
              value: item.id,
              avatar: item.avatarVariants?.thumb?.url,
              avatarVariants: item.avatarVariants,
              supportingObj: {
                name: item.fullName,
                description: item.email,
                defaultColour: item.defaultColour
              }
            }
          })

          return resolve({ metadata, collection: cloneData })
        })
    })
  return promiseMembersOwnerOptionsFetchAll
}
export default usePromiseOwnerOptionsFetchAll
