import { create } from 'zustand'
import {
  createApplicantFeedbackSlice,
  ApplicantFeedbackSlice
} from './open-applicant-feedback-drawer-slice'

const useApplicantFeedbackStore = create<ApplicantFeedbackSlice>()((...a) => ({
  ...createApplicantFeedbackSlice(...a)
}))

export default useApplicantFeedbackStore
