import { TFunction } from 'i18next'
import { z } from 'zod'
import { removeHTMLTags } from '~/core/utilities/common'

const schemaSubmitFeedbackJobInterviewKitsForm = (t: TFunction) => {
  return z.object({
    id: z.number().optional(),
    jobIkitId: z.number().optional(),
    interviewId: z.number().optional(),
    status: z.string().optional(),
    ikitSessions: z
      .array(
        z.object({
          id: z.string().optional(),
          name: z.string().optional(),
          position: z.number().optional(),
          metrics: z
            .array(
              z.object({
                id: z.string().optional(),
                name: z.string().optional(),
                score: z.any().optional()
              })
            )
            .optional(),
          questions: z
            .array(
              z.object({
                id: z.string().optional(),
                content: z.string().optional(),
                answer: z
                  .string()
                  .max(1000, {
                    message: `${t('form:field_max_number_required', {
                      number: 1000
                    })}`
                  })
                  .optional()
              })
            )
            .optional()
        })
      )
      .optional(),
    overallFeedback: z.array(z.string()).min(1, {
      message: `${t('form:requiredField')}`
    }),
    note: z
      .string()
      .refine(
        async (content) => removeHTMLTags(content || '').length <= 50000,
        {
          message: `${t('form:field_max_number_required', { number: 50000 })}`
        }
      )
      .optional()
  })
}

export default schemaSubmitFeedbackJobInterviewKitsForm
