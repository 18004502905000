import { gql } from 'urql'
import { AssigneeType } from '../types'

const QueryAssigneesList = gql<
  {
    assigneesList: {
      collection: Array<AssigneeType>
      metadata: {
        totalCount: number
      }
    }
  },
  { profileId: number; applicantId: number }
>`
  query (
    $page: Int
    $limit: Int
    $search: String
    $profileId: Int
    $applicantId: Int
  ) {
    assigneesList(
      page: $page
      limit: $limit
      search: $search
      profileId: $profileId
      applicantId: $applicantId
    ) {
      collection {
        id
        fullName
        avatarVariants
        defaultColour
        roles {
          id
          name
        }
      }
      metadata {
        totalCount
      }
    }
  }
`

export default QueryAssigneesList
