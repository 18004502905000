export const stageGroupInfoLocal: {
  [id: string]: {
    position: number
    disabled: boolean
  }
} = {
  active_process: {
    position: 2,
    disabled: false
  },
  applicants: {
    position: 1,
    disabled: true
  },
  hires: {
    position: 3,
    disabled: false
  }
}

export const mappingColorByStageType = (colorClassName: string) => {
  let color = ''
  switch (colorClassName) {
    case 'sourced':
      color = 'chart-sourced'
      break
    case 'applied':
      color = 'chart-applied'
      break
    case 'screening':
      color = 'chart-screening'
      break
    case 'interview':
      color = 'chart-interview'
      break
    case 'offer':
      color = 'chart-offer'
      break
    case 'hired':
      color = 'chart-hired'
      break
    case 'client-submission':
      color = 'chart-client-submission'
      break
    case 'clientSubmission':
      color = 'chart-clientSubmission'
      break
    case 'onboard':
      color = 'chart-onboard'
      break
    case 'probation':
      color = 'chart-probation'
      break

    default:
      break
  }

  return color
}

export const canEditStageGroups = ['active_process', 'hires']

export const colorBg: { [id: string]: string } = {
  1: 'bg-chart-50',
  2: 'bg-chart-100',
  3: 'bg-orange-500',
  4: 'bg-chart-400',
  5: 'bg-chart-200',
  6: 'bg-chart-300',
  7: 'bg-chart-500'
}

export const stageColor: { [id: string]: string } = {
  1: '#996CFF',
  2: '#5B8DFF',
  3: '#FFB185',
  4: '#F3CE70',
  5: '#52B3D0',
  6: '#61D2AA'
}
