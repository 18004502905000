import { formatDistanceToNowStrict } from 'date-fns'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import useStaticData from 'src/hooks/data/use-static-data'
import HTMLDisplay from '~/components/HTMLDisplay'
import Authorization from '~/components/Security/Authorization'
import { useClassBasedTopSpace } from '~/components/Subscription/TopSpace'
import { Avatar } from '~/core/ui/Avatar'
import { Badge, IColorBadgeType } from '~/core/ui/Badge'
import { Button } from '~/core/ui/Button'
import { CloseButton } from '~/core/ui/CloseButton'
import { IDotColorProps } from '~/core/ui/Dot'
import { ScrollArea } from '~/core/ui/ScrollArea'
import { TypographyText } from '~/core/ui/Text'
import { Tooltip } from '~/core/ui/Tooltip'
import { cn } from '~/core/ui/utils'
import { defaultFormatDate } from '~/core/utilities/format-date'
import useApplicantFeedbackStore from '~/lib/features/applicant-feedback/store'
import { FEEDBACK_OPTIONS } from '~/lib/features/calendar/utilities/enum.cva'
import { ICandidateApplicant } from '~/lib/features/candidates/types'
import { IStageType } from '~/lib/features/jobs/types'
import { JOB_COLOR_STAGE_NAME } from '~/lib/features/jobs/utilities/enum'
import { mappingColorByStageType } from '~/lib/features/settings/hiring-pipelines/utilities/common'
import useBoundStore from '~/lib/store'
import ApplicantFeedbackFormView from './Form/ApplicantFeedbackFormView'

interface ApplicantFeedbackRightViewProps {
  isDrawer: boolean
  applicantShow?: ICandidateApplicant
  callback?: () => void
  onClose?: () => void
}

const InterviewFeedbackRightView = (props: ApplicantFeedbackRightViewProps) => {
  const { t, i18n } = useTranslation()
  const { isDrawer, applicantShow, callback, onClose } = props
  const user = useBoundStore((state) => state.user)
  const currentRole = useBoundStore((state) => state.currentRole)
  const { applicantDrawer, setApplicantDrawer } = useApplicantFeedbackStore()
  const [openForm, setOpenForm] = useState(false)
  const isNotOpenForm = !openForm && !applicantDrawer?.openForm
  // ------- CALC HEIGHT -------
  const height = useClassBasedTopSpace({
    34: isDrawer === false ? 'calc(100vh - 91px)' : 'calc(100vh - 2px)',
    default: 'calc(100vh - 57px)'
  })
  const containerHeightClass = useClassBasedTopSpace({
    34: isDrawer === false ? 'h-[calc(100vh_-_91px)]' : 'h-[calc(100vh_-_2px)]',
    default: 'h-[calc(100vh_-_2px)]'
  })
  // ------- END CALC HEIGHT -------
  const jobIkitEvaluation = applicantShow?.jobIkitEvaluation
  const feedbackFilter = FEEDBACK_OPTIONS.map((item) => ({
    ...item,
    label: `${t(`candidates:feedback:${item.value}`)}`
  })).filter((s) => s.value === jobIkitEvaluation?.overallFeedback)

  const stageTypes = useStaticData({
    keyName: 'agency_stageTypes',
    locale: i18n.language
  })
  const getColorClassName = (stageTypes || []).filter(
    (s: IStageType) =>
      String(s.id) ===
      (applicantShow?.jobIkitEvaluation?.jobStages &&
      applicantShow?.jobIkitEvaluation?.jobStages?.length > 0
        ? String(applicantShow?.jobIkitEvaluation?.jobStages[0].stageTypeId)
        : String(applicantShow?.jobStage?.stageTypeId))
  )?.[0]?.colorClassName

  const stageLabel =
    applicantShow?.jobIkitEvaluation?.jobStages &&
    applicantShow?.jobIkitEvaluation?.jobStages?.length > 0
      ? String(applicantShow?.jobIkitEvaluation?.jobStages[0].stageLabel)
      : String(applicantShow?.jobStage?.stageLabel)

  return applicantShow ? (
    <div className="w-full">
      <>
        {isNotOpenForm ? (
          <div
            className={cn(
              containerHeightClass,
              'relative flex min-h-full flex-col'
            )}>
            <ScrollArea
              rootStyle={{
                height: height
              }}>
              <div className="px-6 pt-4">
                <div className="flex items-center justify-between">
                  <div className="flex items-center space-x-2">
                    <TypographyText className="text-base font-semibold text-gray-900">
                      {t('interview:feedback_modal:title')}
                    </TypographyText>
                    <div className="flex-none">
                      <Badge
                        color={
                          JOB_COLOR_STAGE_NAME(
                            getColorClassName
                          ) as IColorBadgeType
                        }
                        dotColor={
                          mappingColorByStageType(
                            getColorClassName
                          ) as IDotColorProps
                        }
                        size="md"
                        radius="rounded"
                        type="dotLeading">
                        {stageLabel}
                      </Badge>
                    </div>
                  </div>
                </div>
                <div className="mt-px">
                  <TypographyText className="text-xs font-normal text-gray-600">
                    {t(
                      'interview:application_feedback_modal:share_candidate_strengths'
                    )}
                  </TypographyText>
                </div>
                <CloseButton
                  iconClassName="w-5 h-5 absolute right-3 top-3"
                  onClick={onClose}
                />
              </div>

              {applicantShow?.jobIkitEvaluation ? (
                <div className="space-y-4 py-4">
                  <div className="bg-gray-50 px-6 py-2.5">
                    <TypographyText className="text-sm font-medium text-gray-900">
                      {t(
                        'interview:application_feedback_modal:overallFeedback'
                      )}
                    </TypographyText>
                  </div>
                  <div className="space-y-4 px-6">
                    <div
                      className={cn(
                        'flex space-x-3',
                        jobIkitEvaluation?.note ? '' : 'items-center'
                      )}>
                      <div className="py-1.5">
                        <Avatar
                          size="md"
                          color={jobIkitEvaluation?.user?.defaultColour}
                          alt={jobIkitEvaluation?.user?.fullName}
                          src={
                            jobIkitEvaluation?.user?.avatarVariants?.thumb?.url
                          }
                        />
                      </div>
                      <div className="flex-1 space-y-1">
                        <div className="flex items-center justify-between space-x-2">
                          <div className="flex space-x-2">
                            <Tooltip
                              align="start"
                              content={jobIkitEvaluation?.user?.fullName}>
                              <TypographyText className="line-clamp-1 text-sm font-medium text-gray-900">
                                {jobIkitEvaluation?.user?.fullName}
                              </TypographyText>
                            </Tooltip>

                            <Badge
                              variant="outline"
                              size="md"
                              radius="rounded"
                              classNameText="truncate"
                              iconSVG={feedbackFilter[0].iconMenus}
                              type="iconLeading">
                              {feedbackFilter?.[0]?.label}
                            </Badge>
                          </div>
                          {jobIkitEvaluation?.updatedAt ? (
                            <Tooltip
                              align="end"
                              content={defaultFormatDate(
                                new Date(jobIkitEvaluation.updatedAt)
                              )}>
                              <TypographyText className="whitespace-nowrap text-sm text-gray-600">
                                {formatDistanceToNowStrict(
                                  new Date(jobIkitEvaluation.updatedAt)
                                )}
                              </TypographyText>
                            </Tooltip>
                          ) : null}
                        </div>
                        <HTMLDisplay
                          className="text-sm text-gray-900"
                          content={jobIkitEvaluation?.note}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </ScrollArea>

            <div
              className={cn(
                'flex items-center border-t border-gray-100 px-6 py-4',
                'justify-end'
              )}>
              <Authorization
                ownerId={parseInt(
                  String(applicantShow?.jobIkitEvaluation?.user?.id)
                )}>
                <Button
                  className="mr-3"
                  size="sm"
                  type="secondary"
                  label={`${t('button:editYourFeedback')}`}
                  onClick={() => setOpenForm(true)}
                />
              </Authorization>
              <Button
                size="sm"
                label={`${t('button:close')}`}
                onClick={onClose}
              />
            </div>
          </div>
        ) : (
          <ApplicantFeedbackFormView
            isDrawer={isDrawer}
            onClose={onClose}
            applicantShow={applicantShow}
            callbackReFetch={() => {
              setOpenForm(false)
              callback && callback()
            }}
            callback={onClose}
          />
        )}
      </>
    </div>
  ) : null
}

export default InterviewFeedbackRightView
