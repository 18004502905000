import Link from 'next/link'
import { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import configuration from '~/configuration'
import { Badge } from '~/core/ui/Badge'
import useBoundStore from '~/lib/store'
import useSubscriptionPlan from './useSubscriptionPlan'

const SubscriptionBadge = ({ isDisplayAtSideBar = false }) => {
  const { t } = useTranslation()
  const { data } = useSubscriptionPlan()

  const color = isDisplayAtSideBar
    ? data?.daysLeft && data.daysLeft > 7
      ? 'green'
      : 'red'
    : 'green'
  return data?.isTrial && data?.daysLeft ? (
    <Badge
      radius="circular"
      variant="default"
      type="notification"
      size="xs"
      color={color}>
      {isDisplayAtSideBar
        ? data?.daysLeft
          ? data.daysLeft > 7
            ? `${t('settings:plan:trial')}`
            : `${t('settings:plan:trialLeft', { trial: data.daysLeft })}`
          : ''
        : `${t('settings:plan:trial')}`}
    </Badge>
  ) : (
    <></>
  )
}
export const SubscriptionDayLeft = () => {
  const { t } = useTranslation()
  const { data } = useSubscriptionPlan()
  const user = useBoundStore((state) => state.user)

  const color = data?.daysLeft && data?.daysLeft > 7 ? 'green' : 'red'
  return data?.isTrial && data?.daysLeft ? (
    <>
      {user.ownTenant ? (
        <div className="mt-1">
          <Link
            href={configuration.path.settings.plans}
            onClick={(e) => e.stopPropagation()}>
            <Badge
              icon="ArrowRight"
              type="trailingIcon"
              color={color}
              size="md"
              radius="circular">
              {t('settings:plan:remainTrialDaysLeft', {
                remainingTrialDays: data?.daysLeft
              })}
            </Badge>
          </Link>
        </div>
      ) : (
        <div className="mt-1">
          <a onClick={(e) => e.stopPropagation()}>
            <Badge color={color} size="md" radius="circular">
              {t('settings:plan:remainTrialDaysLeft', {
                remainingTrialDays: data?.daysLeft
              })}
            </Badge>
          </a>
        </div>
      )}
    </>
  ) : (
    <></>
  )
}
export const SubscriptionBadgeOverlay = ({
  children,
  hide
}: PropsWithChildren<{ hide?: boolean }>) => {
  return (
    <div className="relative">
      {!hide && (
        <div className="absolute top-[-11px] z-10 " style={{ left: '50%' }}>
          <div
            style={{ left: '-50%', width: 'max-content' }}
            className="relative">
            <SubscriptionBadge isDisplayAtSideBar={true} />
          </div>
        </div>
      )}
      {children}
    </div>
  )
}
export default SubscriptionBadge
