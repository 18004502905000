import { ReactNode } from 'react'
import useBoundStore from '~/lib/store'

export const checkIsOwner = ({
  userId,
  ownerId
}: {
  userId?: number
  ownerId: number
}) => userId && (!ownerId || userId === ownerId)

const Authorization = ({
  children,
  ownerId
}: {
  children: ReactNode
  ownerId?: number
}) => {
  const { user } = useBoundStore()
  return (
    <>{user.id && (!ownerId || parseInt(user.id) === ownerId) && children}</>
  )
}

export default Authorization
