import configuration from '~/configuration'
import { IRouterWithID, ISelectOption } from '~/core/@types/global'
import useContextGraphQL, {
  IResponseContextResult
} from '~/core/middleware/use-context-graphQL'
import QueryHiringMembersList from '../graphql/query-hiring-members-list'
import { FilterHiringMembersListType, HiringMembersType } from '../types'
import { ROLE } from '~/core/constants/role'
import useBoundStore from '~/lib/store'

interface IHiringMembersListProps {
  everyoneOption?: ISelectOption
  profileId?: IRouterWithID
  applicantId?: IRouterWithID
  jobId?: number
  talentPoolId?: number
  avatarSize?: string
  currentUserAsMe?: boolean
}

const useQueryHiringMembersList = (props: IHiringMembersListProps) => {
  const {
    everyoneOption,
    profileId,
    applicantId,
    jobId,
    talentPoolId,
    avatarSize,
    currentUserAsMe = false
  } = props
  const { clientGraphQL } = useContextGraphQL()
  const user = useBoundStore((state) => state.user)

  const promiseHiringMemberOptions = (
    params = {} as FilterHiringMembersListType
  ) =>
    clientGraphQL
      .query(QueryHiringMembersList, {
        ...params,
        ...(profileId && !applicantId
          ? { profileId: Number(profileId) }
          : undefined),
        ...(applicantId ? { applicantId: Number(applicantId) } : undefined),
        ...(jobId ? { jobId: Number(jobId) } : undefined),
        ...(talentPoolId ? { talentPoolId: talentPoolId } : undefined)
      })
      .toPromise()
      .then((result: IResponseContextResult<HiringMembersType>) => {
        if (result.error) {
          return {
            metadata: {
              totalCount: configuration.defaultAsyncLoadingOptions
            },
            collection: []
          }
        }

        const { hiringMembersList } = result.data
        const collection = hiringMembersList?.collection || []
        const metadata = hiringMembersList?.metadata || {}
        const cloneData = collection.map((item) => {
          return {
            value: item.id,
            avatar: item.avatarVariants?.thumb?.url,
            avatarVariants: item.avatarVariants,
            ...(avatarSize ? { avatarSize } : {}),
            supportingObj: {
              name:
                currentUserAsMe && Number(item.id) === Number(user.id)
                  ? 'Me'
                  : item.fullName,
              description: item.email,
              defaultColour: item.defaultColour
            }
          }
        })

        const mergeData =
          params.page === 1
            ? everyoneOption
              ? [everyoneOption, ...cloneData]
              : cloneData
            : cloneData

        return {
          metadata: { ...metadata, totalCount: metadata.totalCount + 1 },
          collection: mergeData
        }
      })

  const promiseMentionMembersOptions = (
    params = {} as FilterHiringMembersListType
  ) =>
    clientGraphQL
      .query(QueryHiringMembersList, {
        ...params,
        ...(profileId && !applicantId
          ? { profileId: Number(profileId) }
          : undefined),
        ...(applicantId ? { applicantId: Number(applicantId) } : undefined),
        ...(jobId ? { jobId: Number(jobId) } : undefined),
        ...(talentPoolId ? { talentPoolId: talentPoolId } : undefined)
      })
      .toPromise()
      .then((result: IResponseContextResult<HiringMembersType>) => {
        if (result.error) {
          return {
            metadata: {
              totalCount: configuration.defaultAsyncLoadingOptions
            },
            collection: []
          }
        }

        const { hiringMembersList } = result.data
        const collection = hiringMembersList?.collection || []
        const metadata = hiringMembersList?.metadata || {}
        const cloneData = collection.map((item) => {
          return {
            value: item.id,
            avatar: item.avatarVariants?.thumb?.url,
            avatarVariants: item.avatarVariants,
            ...(avatarSize ? { avatarSize } : {}),
            supportingObj: {
              name:
                currentUserAsMe && Number(item.id) === Number(user.id)
                  ? 'Me'
                  : item.fullName,
              description: item.email,
              defaultColour: item.defaultColour
            }
          }
        })

        return {
          metadata: { ...metadata, totalCount: metadata.totalCount + 1 },
          collection: cloneData
        }
      })

  return {
    promiseHiringMemberOptions,
    promiseMentionMembersOptions,
    optionHiringMemberDefault: everyoneOption
  }
}
export default useQueryHiringMembersList
