import { FC, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IRouterWithID, ISelectOption } from '~/core/@types/global'
import { IconButton } from '~/core/ui/IconButton'
import { TextButton } from '~/core/ui/TextButton'
import { Tooltip } from '~/core/ui/Tooltip'
import { catchErrorFromGraphQL } from '~/core/utilities/catch-api-error'
import {
  CandidateProfileInputType,
  ICandidateProfile,
  LanguagesType
} from '~/lib/features/candidates/types'
import useBoundStore from '~/lib/store'
import DisableControlByPermission from '../../DisableControlByPermission'
import LanguageModal from './LanguageModal'

const LanguageView: FC<{
  data: ICandidateProfile
  onUpdateProfile: (
    data: CandidateProfileInputType & { paramType: string }
  ) => Promise<boolean | void>
  applicantId?: IRouterWithID
}> = ({ data, onUpdateProfile, applicantId }) => {
  const { t } = useTranslation()
  const { setToast } = useBoundStore()
  const [openLanguageModal, setOpenLanguageModal] = useState(false)
  const [defaultValue, setDefaultValue] = useState<{
    index?: number
    language?: ISelectOption
    proficiency?: ISelectOption
  }>({})
  const getIndexForNewOne = useCallback((languages?: LanguagesType[]) => {
    const languagesCount = languages?.length || 0
    const lastIndex = languages?.[languagesCount - 1]?.index || 0

    return lastIndex + 1
  }, [])

  const onSubmitLanguage = (value: {
    index: number
    language: ISelectOption
    proficiency: ISelectOption
  }) => {
    const isEdit = !!defaultValue?.language?.value

    const languageObj = {
      index: isEdit
        ? defaultValue.index
        : getIndexForNewOne(data?.permittedFields?.languages?.value),
      language: value.language.value,
      languageDescription: value.language.supportingObj?.name,
      proficiency: value.proficiency?.value || '',
      proficiencyDescription: value.proficiency?.supportingObj?.name || ''
    }

    return onUpdateProfile({
      languages: languageObj
        ? [
            ...(data?.permittedFields?.languages?.value || []).filter(
              (lang) => lang.index !== languageObj.index
            ),
            languageObj
          ]
        : undefined,
      paramType: 'languages'
    }).then((result) => {
      if (result) {
        setOpenLanguageModal(false)
        setDefaultValue({})
        setToast({
          open: true,
          type: 'success',
          title: isEdit
            ? `${t(
                'candidates:tabs:candidateOverview:languages:language_updated'
              )}`
            : `${t(
                'candidates:tabs:candidateOverview:languages:language_added'
              )}`
        })
      }
    })
  }

  return (
    <div className="space-y-1.5">
      {data?.permittedFields?.languages?.value &&
        data?.permittedFields?.languages?.value?.length > 0 &&
        data?.permittedFields?.languages?.value?.map((item) => {
          return (
            <div className="group" key={item.index}>
              <div className="flex items-center">
                <div className="text-sm text-gray-900">
                  {item.languageDescription}
                </div>
                {item.proficiencyDescription && (
                  <>
                    <div className="mx-2 h-0.5 w-0.5 bg-gray-400" />
                    <div className="text-sm text-gray-600">
                      {item.proficiencyDescription}
                    </div>
                  </>
                )}
                <DisableControlByPermission applicantId={applicantId}>
                  <div className="relative top-3 h-2">
                    <div className="absolute bottom-0 left-2 flex rounded border-[1px] border-solid border-gray-100 bg-white p-0.5 opacity-0 shadow-sm group-hover:opacity-100">
                      <div className="mr-1">
                        <Tooltip content={`${t('button:edit')}`}>
                          <IconButton
                            onClick={() => {
                              setOpenLanguageModal(true)
                              setDefaultValue({
                                index: item.index,
                                language: {
                                  value: item.language || '',
                                  supportingObj: {
                                    name: item.languageDescription || ''
                                  }
                                },
                                proficiency: item.proficiencyDescription
                                  ? {
                                      value: item.proficiency || '',
                                      supportingObj: {
                                        name: item.proficiencyDescription || ''
                                      }
                                    }
                                  : undefined
                              })
                            }}
                            type="secondary"
                            size="xs"
                            iconMenus="Edit3Icon"
                          />
                        </Tooltip>
                      </div>

                      <Tooltip content={`${t('button:delete')}`}>
                        <IconButton
                          onClick={() => {
                            onUpdateProfile({
                              languages: [
                                {
                                  ...item,
                                  _destroy: true
                                }
                              ],
                              paramType: 'languages'
                            }).then(() => {
                              setDefaultValue({})
                              setToast({
                                open: true,
                                type: 'success',
                                title: `${t(
                                  'candidates:tabs:candidateOverview:languages:language_deleted'
                                )}`
                              })
                            })
                          }}
                          type="secondary-destructive"
                          size="xs"
                          iconMenus="Trash2"
                        />
                      </Tooltip>
                    </div>
                  </div>
                </DisableControlByPermission>
              </div>
            </div>
          )
        })}
      <DisableControlByPermission
        applicantId={applicantId}
        text={
          <>
            {data?.permittedFields?.languages?.value &&
              data?.permittedFields?.languages?.value?.length <= 0 && (
                <div className="text-sm text-gray-600 ">
                  {t('candidates:tabs:candidateOverview:notAvailable')}
                </div>
              )}
          </>
        }>
        <div>
          <TextButton
            iconMenus="Plus"
            underline={false}
            size="md"
            label={`${t(
              'candidates:tabs:candidateOverview:languages:addLanguage'
            )}`}
            onClick={() => {
              setOpenLanguageModal(true)
              setDefaultValue({
                index: getIndexForNewOne(
                  data?.permittedFields?.languages?.value
                )
              })
            }}
          />
        </div>
      </DisableControlByPermission>
      <LanguageModal
        open={openLanguageModal}
        setOpen={setOpenLanguageModal}
        onSubmitLanguage={onSubmitLanguage}
        defaultValue={defaultValue}
      />
    </div>
  )
}

export default LanguageView
