import { gql } from 'urql'
import { HiringMembersType } from '../types'

const QueryHiringMembersList = gql<
  {
    hiringMembersList: {
      collection: Array<HiringMembersType>
      metadata: {
        totalCount: number
      }
    }
  },
  { profileId: number }
>`
  query (
    $page: Int
    $limit: Int
    $search: String
    $profileId: Int
    $applicantId: Int
    $jobId: Int
    $talentPoolId: Int
  ) {
    hiringMembersList(
      page: $page
      limit: $limit
      search: $search
      profileId: $profileId
      applicantId: $applicantId
      jobId: $jobId
      talentPoolId: $talentPoolId
    ) {
      collection {
        id
        fullName
        email
        avatarVariants
        defaultColour
        roles {
          id
          name
        }
      }
      metadata {
        totalCount
      }
    }
  }
`

export default QueryHiringMembersList
