import { createContext, useContext } from 'react'
type SupportTop = '34'
export const topSpaceContext = createContext<{ top?: SupportTop }>({})
export const useTopSpace = () => {
  const ctx = useContext(topSpaceContext)
  return ctx.top
}
export const useClassBasedTopSpace = (spaceMap: {
  34?: string | number
  default: string | number
}) => {
  const top = useTopSpace()
  return (top && spaceMap[top]) || spaceMap.default
}
export const TopSpaceProvider = topSpaceContext.Provider
