import { useTranslation } from 'react-i18next'
import { useClassBasedTopSpace } from '~/components/Subscription/TopSpace'
import { Divider } from '~/core/ui/Divider'
import { TypographyH5 } from '~/core/ui/Heading'
import { Skeleton } from '~/core/ui/Skeleton'
import { cn } from '~/core/ui/utils'

const InterviewFeedbackSkeletonView = () => {
  const { t } = useTranslation()
  const calcHeight = useClassBasedTopSpace({
    34: 'min-h-[calc(100vh_-_91px)]',
    default: 'min-h-[calc(100vh_-_57px)]'
  })

  return (
    <>
      <div className="flex h-[56px] items-center justify-between px-6">
        <TypographyH5 className="font-medium text-gray-900">
          {t('interview:feedback_modal:interviewFeedback')}
        </TypographyH5>
        <Skeleton className="h-8 w-[calc(38.6%_-_32px)] rounded" />
      </div>
      <Divider />
      <div className={cn('flex w-full', calcHeight)}>
        <div className="w-[61.8%] space-y-4 pt-4">
          <div className="flex flex-1 px-6">
            <Skeleton className="h-10 w-10 rounded-full" />

            <div className="w-1/3">
              <div className="ml-2 flex flex-col">
                <div className="h-6 w-full">
                  <Skeleton className="h-4 w-4/5 rounded" />
                </div>
                <div className="h-5 w-full">
                  <Skeleton className="h-3 w-1/2 rounded" />
                </div>
              </div>
            </div>
          </div>
          <Divider />
          {[1, 2].map((item) => (
            <div key={item} className="w-3/4 space-y-2 px-6">
              <Skeleton className="h-4 w-full rounded" />
              <Skeleton className="h-4 w-3/4 rounded" />
              <Skeleton className="h-4 w-2/4 rounded" />
            </div>
          ))}
        </div>
        <div className="w-[38.6%] space-y-2 border-l border-gray-100 px-6 pt-4">
          <Skeleton className="h-4 w-full rounded" />
          <Skeleton className="h-4 w-3/4 rounded" />
          <Skeleton className="h-4 w-2/4 rounded" />
        </div>
      </div>
    </>
  )
}

export default InterviewFeedbackSkeletonView
