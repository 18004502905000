import { useRef } from 'react'

export const useCatchAndScrollErrorForm = () => {
  const formWrapper = useRef<HTMLDivElement>(null)

  const handleCatchErrorForm = (errors: {
    [key: string]: { message?: string }
  }) => {
    if (errors && Object.keys(errors).length) {
      const getElm = document.getElementById(
        `form-name-${Object.keys(errors)?.[0]}`
      )

      if (getElm) {
        getElm.scrollIntoView({ behavior: 'smooth', block: 'center' })
        getElm.focus({ preventScroll: true })
      }
    }
  }

  return {
    formWrapper,
    handleCatchErrorForm
  }
}
