import { StateCreator } from 'zustand'

export interface ActivitiesListFuncSlice {
  isRefetchActivitiesList: boolean
  setIsRefetchActivitiesList: (flag: boolean) => void
}

export const createRefetchActivitiesSlice: StateCreator<
  ActivitiesListFuncSlice,
  [],
  [],
  ActivitiesListFuncSlice
> = (set: Function) => ({
  isRefetchActivitiesList: false,
  setIsRefetchActivitiesList: (flag: boolean) =>
    set({ isRefetchActivitiesList: flag })
})
