import { ImageLoadingStatus } from '@radix-ui/react-avatar'
import Gleap from 'gleap'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { destroyCookie, parseCookies } from 'nookies'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useBindSidebarUpdateCountEvent } from 'src/events/share-event'
import configuration from '~/configuration'
import { mappingCurrentTenantCookie } from '~/cookies/currentTenant'
import {
  COOKIE_PATH,
  SESSION_COOKIE_CURRENT_TENANT,
  SESSION_COOKIE_CURRENT_TENANT_EXT,
  SESSION_COOKIE_IS_DISABLE_SIDEBAR_ITEM,
  SESSION_EXPIRES_AT_COOKIE_NAME
} from '~/core/constants/cookies'
import { AGENCY_TENANT, REFERRAL, REQUISITION } from '~/core/constants/enum'
import { PUBLIC_APP_URL, PUBLIC_HELP_CENTER_URL } from '~/core/constants/env'
import {
  setSessionCookieClient,
  setSessionCookieExtClient
} from '~/core/middleware/save-session-cookie'
import { Avatar } from '~/core/ui/Avatar'
import IconWrapper from '~/core/ui/IconWrapper'
import If from '~/core/ui/If'
import { Tooltip } from '~/core/ui/Tooltip'
import { cn } from '~/core/ui/utils'
import useNotificationStore from '~/lib/features/notifications/store'
import usePermissionCandidate from '~/lib/features/permissions/hooks/use-permission-candidate'
import usePermissionCompany from '~/lib/features/permissions/hooks/use-permission-company'
import usePermissionJob from '~/lib/features/permissions/hooks/use-permission-job'
import usePermissionPlacement from '~/lib/features/permissions/hooks/use-permission-placement'
import usePermissionSetting from '~/lib/features/permissions/hooks/use-permission-setting'
import { PLAN_FEATURE_KEYS } from '~/lib/features/settings/plans/utilities/enum'
import useWorkSpace from '~/lib/features/settings/workspace/hooks/use-workspace'
import { useSubmitSignOut } from '~/lib/features/sign-out/hooks/use-submit-sign-out'
import useDetectCompanyWithKind from '~/lib/hooks/use-detect-company-with-kind'
import { useUserCheckKindOf } from '~/lib/hooks/use-user-check-kind'
import useBoundStore from '~/lib/store'
import useSubscriptionPlan from '../Subscription/useSubscriptionPlan'
import WithTaskBadgeCount from '../Tasks/withTaskBadgeCount'
import SidebarItem from './SidebarItem'
import SidebarProfile from './SidebarProfile'

// const SidebarSearch = dynamic(() => import('./SidebarSearch'), {
//   ssr: false
// })

const Sidebar = ({
  expired,
  className
}: {
  expired?: boolean
  className?: string
}) => {
  const { t } = useTranslation()
  const router = useRouter()
  const cookies = parseCookies()
  const { handleSignOut } = useSubmitSignOut()
  const {
    isFeatureEnabled,
    isUnLockFeature,
    showIconFeatureFromAdminSetting: onCheckFunc
  } = useSubscriptionPlan()
  const { isCompanyKind } = useDetectCompanyWithKind({ kind: AGENCY_TENANT })
  const {
    user,
    collapseSidebar: openNav,
    showCollapse,
    hideCollapse,
    setOpenTaskDrawer,
    featureSetting
  } = useBoundStore()
  const {
    openNotificationDrawer,
    notificationTotalUnreadCount,
    setOpenNotificationDrawer
  } = useNotificationStore()
  const { canAccessProfile } = usePermissionCandidate({})
  const { canAccessJob, canAccessTalentPool } = usePermissionJob()
  const { canAccessRequisition, canAccessReport, actionAccessReferral } =
    usePermissionSetting()
  const { canAccessPage: canAccessCompany } = usePermissionCompany()
  const { canAccessPage: canAccessPlacement } = usePermissionPlacement()
  const { tenantShow, fetchWorkspace } = useWorkSpace({ shouldPause: true })

  const { userIsAsClient } = useUserCheckKindOf()
  const featureRequisition = featureSetting?.find(
    (item) => item.key === REQUISITION
  )
  const featureReferral = featureSetting?.find((item) => item.key === REFERRAL)

  const signOut = useCallback(async () => {
    await handleSignOut()
    if (Gleap && Gleap.getInstance().initialized === true) {
      Gleap.clearIdentity()
    }
  }, [])

  useEffect(() => {
    if (user?.currentTenant) {
      fetchCompany()
    }
  }, [])

  useEffect(() => {
    if (expired === false && cookies[SESSION_COOKIE_IS_DISABLE_SIDEBAR_ITEM]) {
      destroyCookie(null, SESSION_COOKIE_IS_DISABLE_SIDEBAR_ITEM, {
        path: COOKIE_PATH
      })
    }
  }, [expired])

  const [loadingStatus, setLoadingStatus] = useState<ImageLoadingStatus>('idle')
  const fetchCompany = async () => {
    const src = user.currentTenant?.logoVariants?.thumb?.url

    if (src) {
      let isMounted = true
      const image = new window.Image()

      const updateStatus = (status: ImageLoadingStatus) => () => {
        if (!isMounted) return
        setLoadingStatus(status)
      }

      setLoadingStatus('loading')
      image.onload = updateStatus('loaded')
      image.onerror = updateStatus('error')
      image.src = src
    }
  }

  useEffect(() => {
    if (tenantShow && Object.keys(tenantShow).length > 0) {
      setLoadingStatus('loaded')

      if (user?.currentTenant) {
        const obj = {
          ...user.currentTenant,
          ...tenantShow,
          careerSiteSettings: tenantShow.careerSiteSettings
        }
        const currentTenantObject = mappingCurrentTenantCookie(obj)

        setSessionCookieClient(
          SESSION_COOKIE_CURRENT_TENANT,
          JSON.stringify(currentTenantObject).toString(),
          Number(cookies[SESSION_EXPIRES_AT_COOKIE_NAME])
        )
        setSessionCookieExtClient(
          SESSION_COOKIE_CURRENT_TENANT_EXT,
          JSON.stringify(currentTenantObject).toString(),
          Number(cookies[SESSION_EXPIRES_AT_COOKIE_NAME])
        )
      }
    }
  }, [tenantShow])

  useEffect(() => {
    if (loadingStatus === 'error') {
      fetchWorkspace()
    }
  }, [loadingStatus])

  return (
    <div
      onMouseEnter={(e) => showCollapse()}
      className={cn(
        'clickable-blur fixed z-[50] hidden h-screen min-h-0 flex-col border-r border-gray-100 bg-white transition-all duration-150 dark:border-gray-700 dark:bg-gray-900 tablet:flex tablet:min-w-[61px]',
        openNav ? 'shadow-sidebar tablet:w-[228px]' : 'tablet:w-[61px]',
        className
      )}>
      {openNav ? (
        <div
          onMouseEnter={(e) => hideCollapse()}
          className="fixed bottom-0 left-[228px] right-0 top-0"
        />
      ) : null}
      <div className="flex flex-1 flex-col overflow-y-auto overflow-x-hidden pb-3 pt-3">
        <div className="flex-1">
          <div className="flex flex-shrink-0 items-center px-3.5 pb-3">
            <div className="relative h-8">
              <Link
                href={configuration.path.default}
                className={cn(
                  'absolute left-0 top-0 z-[51] h-8 min-w-[32px]',
                  openNav ? 'min-w-[133px]' : 'min-w-[32px]'
                )}>
                <Image
                  width={133}
                  height={32}
                  alt="logo"
                  src="/img/logo/logo_with_label.png"
                  className={!openNav ? 'hidden' : ''}
                />
                <Image
                  width={32}
                  height={32}
                  alt="logo"
                  src="/img/logo/logo-primary.png"
                  className={openNav ? 'hidden' : ''}
                />
              </Link>
            </div>
          </div>

          <div className="px-2.5">
            <SidebarItem
              openNav={openNav}
              selected={router.pathname === configuration.path.dashboard}
              icon={<IconWrapper className="" name="Home" size={18} />}
              title={`${t('common:sidebar:management:list:dashboard')}`}
              link={configuration.path.dashboard}
              disabled={expired}
            />
          </div>

          <If
            condition={
              isFeatureEnabled(PLAN_FEATURE_KEYS.notification) &&
              isUnLockFeature(PLAN_FEATURE_KEYS.notification)
            }>
            <div className="mt-1 px-2.5">
              <SidebarItem
                openNav={openNav}
                badge={notificationTotalUnreadCount}
                selected={openNotificationDrawer}
                icon={<IconWrapper className="" name="Bell" size={18} />}
                title={`${t('common:sidebar:management:list:notifications')}`}
                noLink={true}
                disabled={expired}
                onClick={() => {
                  hideCollapse()
                  setOpenNotificationDrawer(true)
                }}
              />
            </div>
          </If>

          <div className="mt-1 px-2.5">
            <WithTaskBadgeCount>
              {({ totalCount }) => (
                <SidebarItem
                  openNav={openNav}
                  badge={totalCount}
                  selected={router.pathname === configuration.path.tasks.list}
                  icon={
                    <IconWrapper className="" name="CheckCircle" size={18} />
                  }
                  title={`${t('common:sidebar:management:list:tasks')}`}
                  noLink={true}
                  disabled={expired}
                  onClick={() => {
                    hideCollapse()
                    setOpenTaskDrawer(true)
                  }}
                />
              )}
            </WithTaskBadgeCount>
          </div>

          <div className="mt-1 px-2.5">
            <nav
              className="flex-1 space-y-1 bg-white dark:bg-gray-900"
              aria-label="Sidebar">
              <If condition={canAccessProfile}>
                <SidebarItem
                  openNav={openNav}
                  selected={
                    router.pathname === configuration.path.candidates.list
                  }
                  link={configuration.path.candidates.list}
                  icon={<IconWrapper className="" name="User" size={18} />}
                  title={`${t('common:sidebar:management:list:candidates')}`}
                  disabled={expired}
                />
              </If>

              <If condition={canAccessJob}>
                <SidebarItem
                  openNav={openNav}
                  selected={router.pathname === configuration.path.jobs.list}
                  link={configuration.path.jobs.list}
                  icon={<IconWrapper className="" name="Briefcase" size={18} />}
                  title={`${t('common:sidebar:management:list:jobs')}`}
                  disabled={expired}
                />
              </If>

              {isCompanyKind === false ? (
                <If
                  condition={
                    canAccessRequisition &&
                    featureRequisition?.enabling_feature &&
                    isFeatureEnabled(PLAN_FEATURE_KEYS.requisition) &&
                    isUnLockFeature(PLAN_FEATURE_KEYS.requisition)
                  }>
                  <SidebarItem
                    openNav={openNav}
                    selected={
                      router.pathname === configuration.path.requisitions.list
                    }
                    link={configuration.path.requisitions.list}
                    icon={
                      <IconWrapper className="" name="FileCheck2" size={18} />
                    }
                    disabled={expired}
                    title={`${t(
                      'common:sidebar:management:list:requisitions'
                    )}`}
                  />
                </If>
              ) : null}

              {isCompanyKind === true ? (
                <>
                  <If condition={canAccessCompany}>
                    <SidebarItem
                      openNav={openNav}
                      selected={
                        router.pathname === configuration.path.agency.companies
                      }
                      link={configuration.path.agency.companies}
                      icon={
                        <IconWrapper className="" name="Building" size={18} />
                      }
                      title={`${t('common:sidebar:management:list:companies')}`}
                      disabled={expired}
                    />
                  </If>
                  <If condition={!userIsAsClient()}>
                    <SidebarItem
                      openNav={openNav}
                      selected={
                        router.pathname ===
                        configuration.path.agency.contacts.list
                      }
                      link={configuration.path.agency.contacts.list}
                      icon={
                        <IconWrapper className="" name="Contact" size={18} />
                      }
                      title={`${t('common:sidebar:management:list:contacts')}`}
                      disabled={expired}
                    />
                  </If>
                  <If condition={canAccessPlacement}>
                    <SidebarItem
                      openNav={openNav}
                      selected={
                        router.pathname === configuration.path.placements.list
                      }
                      link={configuration.path.placements.list}
                      icon={
                        <IconWrapper className="" name="DollarSign" size={18} />
                      }
                      title={`${t(
                        'common:sidebar:management:list:placements'
                      )}`}
                      disabled={expired}
                    />
                  </If>
                </>
              ) : null}

              <SidebarItem
                openNav={openNav}
                selected={router.pathname === configuration.path.calendar.list}
                link={configuration.path.calendar.list}
                icon={<IconWrapper className="" name="Calendar" size={18} />}
                title={`${t('common:sidebar:management:list:calendar')}`}
                disabled={expired}
              />

              <If condition={canAccessTalentPool}>
                <SidebarItem
                  openNav={openNav}
                  selected={
                    router.pathname === configuration.path.talentPool.list
                  }
                  link={configuration.path.talentPool.list}
                  icon={
                    <IconWrapper className="" name="FolderSearch" size={18} />
                  }
                  title={`${t('common:sidebar:management:list:talentPool')}`}
                  disabled={expired}
                />
              </If>

              <If
                condition={
                  actionAccessReferral &&
                  featureReferral?.enabling_feature &&
                  isFeatureEnabled(PLAN_FEATURE_KEYS.referral) &&
                  isUnLockFeature(PLAN_FEATURE_KEYS.referral)
                }>
                <SidebarItem
                  openNav={openNav}
                  noLink
                  onClick={() => {
                    window.open(
                      configuration.path.careerHub.jobs(
                        user.currentTenant?.slug as string
                      ),
                      '_blank'
                    )
                  }}
                  icon={
                    <IconWrapper className="" name="FileBoxIcon" size={18} />
                  }
                  disabled={expired}
                  title={`${t('common:sidebar:profile:dropdown:careerHub')}`}
                />
              </If>

              <If condition={canAccessReport}>
                <SidebarItem
                  openNav={openNav}
                  selected={
                    router.pathname === configuration.path.reports.overview
                  }
                  link={configuration.path.reports.overview}
                  icon={<IconWrapper className="" name="PieChart" size={18} />}
                  title={`${t('common:sidebar:management:list:reports')}`}
                  disabled={expired}
                />
              </If>
            </nav>
          </div>
        </div>
      </div>

      <SidebarProfile
        expired={expired}
        user={user}
        signOut={signOut}
        openNav={openNav}
      />

      {/* <If condition={currentRole?.code}>
        <SidebarSearch />
      </If> */}
    </div>
  )
}

export default Sidebar
