const UpdateTaskMutation = `
  mutation (
    $id: Int!,
    $profileId: Int,
    $applicantId: Int,
    $title: String,
    $dueDate: ISO8601DateTime,
    $assigneeIds: [Int!]
    $status: TaskStatus
  ) {
    tasksUpdate(
      input: {
        id: $id,
        profileId: $profileId,
        applicantId: $applicantId,
        title: $title,
        dueDate: $dueDate,
        assigneeIds: $assigneeIds,
        status: $status
      }
    ) {
      task {
        id
      }
    }
  }
`

export default UpdateTaskMutation
