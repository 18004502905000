/* eslint-disable react/display-name */
import {
  CalendarApi,
  DateInput,
  EventChangeArg,
  EventContentArg,
  EventDropArg,
  EventSourceInput,
  FormatterInput,
  MoreLinkAction
} from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction'
import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import { format, subDays } from 'date-fns'
import {
  memo,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import {
  Popover,
  PopoverContent,
  PopoverPortal,
  PopoverTrigger
} from '~/core/ui/Popover'
import { DATE_FORMAT_YYYYMMDD, dayFormatDate, dayShortFormatDate } from '../utilities/format-date'

interface CalendarProps {
  timezone?: string
  height?: string | number
  initialDate?: string
  dataTransform: EventSourceInput
  now?: DateInput | ((this: CalendarApi) => DateInput)
  renderEventContent: (
    eventInfo: EventContentArg,
    isOpenPopoverDetail: boolean
  ) => ReactNode
  renderEventPopoverContent: (
    eventInfo: EventContentArg,
    closeEvent?: () => void
  ) => ReactNode
  renderEventContentTimeGrid: (
    eventInfo: EventContentArg,
    isOpenPopoverDetail: boolean
  ) => ReactNode
  renderEventPopoverContentTimeGrid: (
    eventInfo: EventContentArg,
    closeEvent?: () => void
  ) => ReactNode
  renderMoreLinkContent: (args: { num: number }) => ReactNode
  renderMoreLinkContentTimeGrid: (args: { num: number }) => ReactNode
  moreLinkClickDayGrid?: MoreLinkAction
  moreLinkClickTimeGrid?: MoreLinkAction
  slotDuration?: string
  slotLabelFormat?: FormatterInput | FormatterInput[] | undefined
  onSelectEvent?: (arr1: unknown) => void
  setValueSearch: (arr1: { from_date?: string; to_date?: string }) => void
  onDropEvent?: (eventDropInfo: EventDropArg) => Promise<void>
  onChangeEvent?: (eventChangeInfo: EventChangeArg) => Promise<void>
  onDateClick?: (dateClickArg: DateClickArg, time: Date) => void
  LoadingOverlay?: React.ReactElement
  isFetchingEvents?: boolean
  eventMinHeight?: number

  eventDurationEditable?: boolean
  nowIndicator?: boolean
  editable?: boolean
  selectable?: boolean
  selectMirror?: boolean

  locale?: string
  textOverride?: {
    [key: string]: string
  }
}

const Calendar = (props: CalendarProps) => {
  const {
    textOverride,
    locale,
    timezone = 'local',
    height,
    dataTransform = [],
    initialDate,
    eventMinHeight,
    renderEventContent = undefined,
    renderEventPopoverContent = undefined,
    renderEventContentTimeGrid = undefined,
    renderEventPopoverContentTimeGrid = undefined,
    renderMoreLinkContent,
    renderMoreLinkContentTimeGrid,
    moreLinkClickDayGrid,
    moreLinkClickTimeGrid,
    slotDuration = '01:00:00',
    slotLabelFormat = [
      {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
      }
    ],
    onSelectEvent,
    setValueSearch,
    onDropEvent,
    onChangeEvent,
    onDateClick,
    LoadingOverlay,
    isFetchingEvents,
    eventDurationEditable = false,
    nowIndicator = true,
    now,
    editable = true,
    selectable = true,
    selectMirror = true
  } = props
  const [isLoadView, setIsLoadView] = useState<boolean>(false)
  const calendarRef = useRef<FullCalendar>(null)

  const submitValueSearch = () => {
    const currentStart = calendarRef?.current?.getApi().view.currentStart
    const currentEnd = calendarRef?.current?.getApi().view.currentEnd
    if (setValueSearch) {
      setValueSearch({
        from_date: currentStart
          ? format(currentStart, DATE_FORMAT_YYYYMMDD)
          : undefined,
        to_date: currentEnd
          ? format(subDays(currentEnd, 1), DATE_FORMAT_YYYYMMDD)
          : undefined
      })
    }
  }

  useEffect(() => {
    let timeout: NodeJS.Timeout
    if (!isFetchingEvents && isLoadView) {
      timeout = setTimeout(() => setIsLoadView(false), 200)
    }

    return () => clearTimeout(timeout)
  }, [isFetchingEvents, isLoadView])

  return (
    <div className="full-calendar">
      {isLoadView ? LoadingOverlay : null}
      <FullCalendar
        dayMaxEventRows
        timeZone={timezone}
        ref={calendarRef}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        
        {...{
          eventDidMount: function (event) {
            event.el.removeAttribute('tabIndex');
          },
          initialView: 'dayGridMonth',
          initialDate,
          locale,
          headerToolbar: {
            left: 'today,prev,next title',
            right: 'dayGridMonth,timeGridWeek'
          },
          height,
          events: isLoadView ? [] : dataTransform,
          eventDurationEditable,
          nowIndicator,
          now,
          editable,
          selectable,
          selectMirror,
          dateClick: (dateClickArg) => {
            const time =
              calendarRef?.current?.getApi()?.view?.type === 'dayGridMonth'
                ? new Date()
                : new Date(dateClickArg.date)

            if (onDateClick) {
              onDateClick(dateClickArg, time)
            }
          },
          eventDrop: (eventDropInfo) => {
            if (onDropEvent) {
              onDropEvent(eventDropInfo)
            }
          },
          eventChange: (eventChangeInfo) => {
            if (onChangeEvent) onChangeEvent(eventChangeInfo)
          },
          eventClick: (eventClickInfo) => {
            if (onSelectEvent) {
              const extendProps = eventClickInfo.event._def.extendedProps
              onSelectEvent({ ...extendProps, id: extendProps.id })
            }
          },
          views: {
            dayGrid: {
              // eventContent: renderEventContent,
              eventContent: (eventInfo) => {
                const Component = memo(
                  () => {
                    const [openPopup, setOpenPopup] = useState<boolean>(false)
                    const onClosePopover = useCallback(
                      () => setOpenPopup(false),
                      []
                    )

                    return (
                      <Popover open={openPopup} onOpenChange={setOpenPopup}>
                        <PopoverTrigger asChild>
                          <button
                            type="button"
                            className="flex h-full w-full flex-1">
                            {renderEventContent ? (
                              renderEventContent(eventInfo, openPopup)
                            ) : (
                              <div className="text-xs">
                                {eventInfo?.event?.title}
                              </div>
                            )}
                          </button>
                        </PopoverTrigger>

                        <PopoverPortal>
                          <PopoverContent
                            onOpenAutoFocus={(e) => e.preventDefault()}
                            side="right"
                            align="start"
                            sideOffset={20}
                            className="w-[400px]">
                            {renderEventPopoverContent ? (
                              renderEventPopoverContent(
                                eventInfo,
                                onClosePopover
                              )
                            ) : (
                              <div className="text-xs">
                                {eventInfo?.event?.title}
                              </div>
                            )}
                          </PopoverContent>
                        </PopoverPortal>
                      </Popover>
                    )
                  },
                  () => false
                )
                return <Component />
              },
              allDayMaintainDuration: true,
              dayMaxEvents: 3,
              titleFormat: {
                month: 'short',
                year: 'numeric'
              },
              dayPopoverFormat: {
                weekday: 'short',
                day: '2-digit'
              },
              moreLinkContent: renderMoreLinkContent,
              moreLinkClick: moreLinkClickDayGrid
            },
            timeGrid: {
              eventContent: (eventInfo) => {
                const Component = memo(
                  () => {
                    const [openPopup, setOpenPopup] = useState<boolean>(false)
                    const onClosePopover = useCallback(
                      () => setOpenPopup(false),
                      []
                    )
                    return (
                      <Popover open={openPopup} onOpenChange={setOpenPopup}>
                        <PopoverTrigger asChild>
                          <button
                            type="button"
                            className="flex h-full w-full flex-1">
                            {renderEventContentTimeGrid ? (
                              renderEventContentTimeGrid(eventInfo, openPopup)
                            ) : (
                              <div className="text-xs">
                                {eventInfo?.event?.title}
                              </div>
                            )}
                          </button>
                        </PopoverTrigger>

                        <PopoverPortal>
                          <PopoverContent
                            onOpenAutoFocus={(e) => e.preventDefault()}
                            side="right"
                            align="start"
                            sideOffset={10}
                            className="w-[400px]">
                            {renderEventPopoverContentTimeGrid ? (
                              renderEventPopoverContentTimeGrid(
                                eventInfo,
                                onClosePopover
                              )
                            ) : (
                              <div className="text-xs">
                                {eventInfo?.event?.title}
                              </div>
                            )}
                          </PopoverContent>
                        </PopoverPortal>
                      </Popover>
                    )
                  },
                  () => false
                )
                return <Component />
              },
              slotLabelFormat,
              timeGrid: 47,
              allDaySlot: false,
              eventMaxStack: 2,
              eventMinHeight,
              slotEventOverlap: true,
              titleFormat: {
                month: 'short',
                year: 'numeric'
              },
              slotDuration,
              moreLinkContent: renderMoreLinkContentTimeGrid,
              moreLinkClick: moreLinkClickTimeGrid,
              dayHeaderContent: (args) => {
                const date = new Date(args.date)
                return (
                  <div>
                    <div className="time-grid__day-week">
                      {date && dayShortFormatDate(date)}
                    </div>
                    <div className="time-grid__day-number">
                      {date && dayFormatDate(date)}
                    </div>
                  </div>
                )
              }
            }
          },
          customButtons: {
            next: {
              click: () => {
                setIsLoadView(true)
                calendarRef?.current?.getApi().next()
                submitValueSearch()
              }
            },
            prev: {
              click: () => {
                setIsLoadView(true)
                calendarRef?.current?.getApi().prev()
                submitValueSearch()
              }
            },
            today: {
              text: textOverride?.today || 'Today',
              click: () => {
                setIsLoadView(true)
                calendarRef?.current?.getApi().today()
                submitValueSearch()
              }
            },
            dayGridMonth: {
              text: textOverride?.month || 'Month',
              click: () => {
                setIsLoadView(true)
                calendarRef?.current?.getApi().changeView('dayGridMonth')
                submitValueSearch()
              }
            },
            timeGridWeek: {
              text: textOverride?.week || 'Week',
              click: () => {
                setIsLoadView(true)
                calendarRef?.current?.getApi().changeView('timeGridWeek')
                submitValueSearch()
              }
            }
          }
        }}
      />
    </div>
  )
}

Calendar.displayName = 'Calendar'

export { Calendar }
export type { CalendarProps }

