const QueryAddSubmitFeedbackJobApplicantKits = `
  mutation (
    $applicantId: Int,
    $status: JobIkitStatus!,
    $note: String,
    $overallFeedback: IkitOverallFeedback,
  ) {
    interviewIkitFeedbacksCreate(
      input: {
        applicantId: $applicantId,
        status: $status,
        note: $note,
        overallFeedback: $overallFeedback,
      }
    ) {
      jobIkitEvaluation {
        id
        name
        note
        guideline
        overallFeedback
        status
        jobIkitSessions {
          id
          name
          position
          jobIkitMetrics {
            id
            name
            score
          }
          jobIkitQuestions {
            id
            content
            answer
          }
        }
      }
    }
  }
`

export default QueryAddSubmitFeedbackJobApplicantKits
