import { IRouterWithID } from '~/core/@types/global'
import { IJobInterviewKit } from '../types'

export const mappingDataSubmitFeedbackJobInterviewKits = ({
  source,
  interviewId,
  keepStatus = false
}: {
  source?: IJobInterviewKit
  interviewId?: IRouterWithID
  keepStatus: boolean
}) => {
  return {
    interviewId: interviewId ? Number(interviewId) : undefined,
    status: keepStatus ? source?.status : source?.status || 'publish',
    ikitSessions: (source?.jobIkitSessions || []).map((item) => {
      return {
        id: item.id,
        name: item.name,
        position: item.position,
        metrics: (item.jobIkitMetrics || [])
          .filter((item) => item.name)
          .map((s) => {
            return {
              id: s.id,
              name: s.name,
              score: s.score
            }
          }),
        questions: (item.jobIkitQuestions || [])
          .filter((item) => item.content)
          .map((s) => {
            return {
              id: s.id,
              content: s.content,
              answer: s.answer || ''
            }
          })
      }
    }),
    note: source?.note || '',
    overallFeedback: source?.overallFeedback
      ? [String(source.overallFeedback)]
      : [],
    jobIkitId: source?.id ? Number(source.id) : undefined,
    id: source?.id ? Number(source.id) : undefined
  }
}
