import { IRouterWithID } from '~/core/@types/global'
import { IJobInterviewKit } from '../../settings/interview-kits/types'

export const mappingDataSubmitFeedbackJobApplicantKits = ({
  source,
  keepStatus = false
}: {
  source?: {
    id?: string
    overallFeedback?: Array<string> | string
    status?: string
    note?: string
  }
  interviewId?: IRouterWithID
  keepStatus: boolean
}) => {
  return {
    status: keepStatus ? source?.status : source?.status || 'publish',
    note: source?.note || '',
    overallFeedback: source?.overallFeedback
      ? [String(source.overallFeedback)]
      : [],
    id: source?.id ? Number(source.id) : undefined
  }
}
