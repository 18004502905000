import {
  ACTIONS_PERMISSIONS,
  canAccessFeature,
  canActionFeature,
  canPermissionFeature,
  DROPDOWN_PERMISSIONS,
  PERMISSIONS_LIST
} from '~/core/utilities/feature-permission'
import useBoundStore from '~/lib/store'

const usePermissionJob = () => {
  const { currentRole, permissionSetting } = useBoundStore()

  const canAccessTalentPool = canAccessFeature({
    currentRole,
    permissionSetting,
    keyModule: [PERMISSIONS_LIST.job_management.keyModule],
    keyModuleObject: [
      PERMISSIONS_LIST.job_management.objects.talent_pool.keyModuleObject
    ],
    action: ACTIONS_PERMISSIONS.show
  })

  const canAccessJob = canAccessFeature({
    currentRole,
    permissionSetting,
    keyModule: [PERMISSIONS_LIST.job_management.keyModule],
    keyModuleObject: [
      PERMISSIONS_LIST.job_management.objects.job.keyModuleObject
    ],
    action: ACTIONS_PERMISSIONS.show
  })

  const canAccessFullPermissionJob = canPermissionFeature({
    currentRole,
    permissionSetting,
    keyModule: [PERMISSIONS_LIST.job_management.keyModule],
    keyModuleObject: [
      PERMISSIONS_LIST.job_management.objects.job.keyModuleObject
    ],
    permissionAction: DROPDOWN_PERMISSIONS.full
  })

  const canAccessJobNote = canAccessFeature({
    currentRole,
    permissionSetting,
    keyModule: [PERMISSIONS_LIST.job_management.keyModule],
    keyModuleObject: [
      PERMISSIONS_LIST.job_management.objects.job_note.keyModuleObject
    ],
    action: ACTIONS_PERMISSIONS.show
  })

  const canAccessTalentPoolNote = canAccessFeature({
    currentRole,
    permissionSetting,
    keyModule: [PERMISSIONS_LIST.job_management.keyModule],
    keyModuleObject: [
      PERMISSIONS_LIST.job_management.objects.talent_pool_note.keyModuleObject
    ],
    action: ACTIONS_PERMISSIONS.show
  })

  const canAccessFullPermissionJobIkitEvaluation = canPermissionFeature({
    currentRole,
    permissionSetting,
    keyModule: [PERMISSIONS_LIST.job_management.keyModule],
    keyModuleObject: [
      PERMISSIONS_LIST.job_management.objects.job_ikit_evaluation
        .keyModuleObject
    ],
    permissionAction: DROPDOWN_PERMISSIONS.full
  })

  const actionJob = canActionFeature({
    currentRole,
    permissionSetting,
    keyModule: [PERMISSIONS_LIST.job_management.keyModule],
    keyModuleObject: [
      PERMISSIONS_LIST.job_management.objects.job.keyModuleObject
    ]
  })

  const actionInterview = canActionFeature({
    currentRole,
    permissionSetting,
    keyModule: [PERMISSIONS_LIST.job_management.keyModule],
    keyModuleObject: [
      PERMISSIONS_LIST.job_management.objects.interview.keyModuleObject
    ]
  })

  const actionJobIkit = canActionFeature({
    currentRole,
    permissionSetting,
    keyModule: [PERMISSIONS_LIST.job_management.keyModule],
    keyModuleObject: [
      PERMISSIONS_LIST.job_management.objects.job_ikit.keyModuleObject
    ]
  })

  const actionJobIkitEvaluation = canActionFeature({
    currentRole,
    permissionSetting,
    keyModule: [PERMISSIONS_LIST.job_management.keyModule],
    keyModuleObject: [
      PERMISSIONS_LIST.job_management.objects.job_ikit_evaluation
        .keyModuleObject
    ]
  })

  const actionTalentPool = canActionFeature({
    currentRole,
    permissionSetting,
    keyModule: [PERMISSIONS_LIST.job_management.keyModule],
    keyModuleObject: [
      PERMISSIONS_LIST.job_management.objects.talent_pool.keyModuleObject
    ]
  })

  const actionTalentPoolNote = canActionFeature({
    currentRole,
    permissionSetting,
    keyModule: [PERMISSIONS_LIST.job_management.keyModule],
    keyModuleObject: [
      PERMISSIONS_LIST.job_management.objects.talent_pool_note.keyModuleObject
    ]
  })

  const actionJobNote = canActionFeature({
    currentRole,
    permissionSetting,
    keyModule: [PERMISSIONS_LIST.job_management.keyModule],
    keyModuleObject: [
      PERMISSIONS_LIST.job_management.objects.job_note.keyModuleObject
    ]
  })

  return {
    canAccessJob,
    canAccessFullPermissionJob,
    canAccessTalentPool,
    canAccessTalentPoolNote,
    canAccessJobNote,
    actionJob,
    actionInterview,
    actionJobIkit,
    actionJobIkitEvaluation,
    actionTalentPool,
    actionTalentPoolNote,
    actionJobNote,
    canAccessFullPermissionJobIkitEvaluation
  }
}

export default usePermissionJob
