import {
  ACTIONS_PERMISSIONS,
  canAccessFeature,
  canActionFeature,
  PERMISSIONS_LIST
} from '~/core/utilities/feature-permission'
import useBoundStore from '~/lib/store'

const usePermissionCompany = () => {
  const { currentRole, permissionSetting } = useBoundStore()

  const canAccessPage = canAccessFeature({
    currentRole,
    permissionSetting,
    keyModule: [PERMISSIONS_LIST.company_management.keyModule],
    keyModuleObject: [
      PERMISSIONS_LIST.company_management.objects.company.keyModuleObject
    ],
    action: ACTIONS_PERMISSIONS.show
  })

  const canAccessNote = canAccessFeature({
    currentRole,
    permissionSetting,
    keyModule: [PERMISSIONS_LIST.company_management.keyModule],
    keyModuleObject: [
      PERMISSIONS_LIST.company_management.objects.company_note.keyModuleObject
    ],
    action: ACTIONS_PERMISSIONS.show
  })

  const canAccessTask = canAccessFeature({
    currentRole,
    permissionSetting,
    keyModule: [PERMISSIONS_LIST.company_management.keyModule],
    keyModuleObject: [
      PERMISSIONS_LIST.company_management.objects.company_task.keyModuleObject
    ],
    action: ACTIONS_PERMISSIONS.show
  })

  const canAccessPlacement = canAccessFeature({
    currentRole,
    permissionSetting,
    keyModule: [PERMISSIONS_LIST.placement.keyModule],
    keyModuleObject: [
      PERMISSIONS_LIST.placement.objects.placement.keyModuleObject
    ],
    action: ACTIONS_PERMISSIONS.show
  })

  const actionCompanyContact = canActionFeature({
    currentRole,
    permissionSetting,
    keyModule: [PERMISSIONS_LIST.company_management.keyModule],
    keyModuleObject: [
      PERMISSIONS_LIST.company_management.objects.company_contact
        .keyModuleObject
    ]
  })

  const actionCompanyNote = canActionFeature({
    currentRole,
    permissionSetting,
    keyModule: [PERMISSIONS_LIST.company_management.keyModule],
    keyModuleObject: [
      PERMISSIONS_LIST.company_management.objects.company_note.keyModuleObject
    ]
  })

  const actionCompanyTask = canActionFeature({
    currentRole,
    permissionSetting,
    keyModule: [PERMISSIONS_LIST.company_management.keyModule],
    keyModuleObject: [
      PERMISSIONS_LIST.company_management.objects.company_task.keyModuleObject
    ]
  })

  const actionCompany = canActionFeature({
    currentRole,
    permissionSetting,
    keyModule: [PERMISSIONS_LIST.company_management.keyModule],
    keyModuleObject: [
      PERMISSIONS_LIST.company_management.objects.company.keyModuleObject
    ]
  })

  return {
    canAccessPage,
    canAccessNote,
    canAccessTask,
    canAccessPlacement,
    actionCompany,
    actionCompanyContact,
    actionCompanyNote,
    actionCompanyTask
  }
}

export default usePermissionCompany
