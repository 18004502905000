import { create } from 'zustand'
import { createUploadSlice, UploadSlice } from './open-upload-drawer-slice'
import {
  createRefetchActivitiesSlice,
  ActivitiesListFuncSlice
} from './refetch-activities-list.slice'
const useCandidateStore = create<UploadSlice & ActivitiesListFuncSlice>()(
  (...a) => ({
    ...createUploadSlice(...a),
    ...createRefetchActivitiesSlice(...a)
  })
)

export default useCandidateStore
