const CreateTaskMutation = `
  mutation (
    $title: String!,
    $profileId: Int,
    $applicantId: Int,
    $dueDate: ISO8601DateTime,
    $assigneeIds: [Int!]!
  ) {
    tasksCreate(
      input: {
        title: $title,
        profileId: $profileId,
        applicantId: $applicantId,
        dueDate: $dueDate,
        assigneeIds: $assigneeIds
      }
    ) {
      task {
        id
      }
    }
  }
`

export default CreateTaskMutation
