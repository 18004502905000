import { useEffect, useState } from 'react'

export const useMoreLinkPopover = () => {
  const [moreLinkPopover, setMoreLinkPopover] = useState<HTMLElement>()

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const eleMoreLinkPopover = document.querySelector('.fc-more-popover')

      eleMoreLinkPopover &&
        setMoreLinkPopover(eleMoreLinkPopover as HTMLElement)
    }
  }, [])

  return { moreLinkPopover }
}
