import { useTranslation } from 'react-i18next'
import { FC } from 'react'
import { ISelectOption } from '~/core/@types/global'
import { Dialog } from '~/core/ui/Dialog'
import LanguageForm from './LanguageForm'

const LanguageModal: FC<{
  open: boolean
  setOpen: (open: boolean) => void
  onSubmitLanguage: (value: {
    index: number
    language: ISelectOption
    proficiency: ISelectOption
  }) => Promise<void>
  defaultValue?: {
    language?: ISelectOption
    proficiency?: ISelectOption
  }
}> = ({ open, setOpen, onSubmitLanguage, defaultValue }) => {
  const { t } = useTranslation()
  const isEdit = defaultValue?.language?.value
  return (
    <Dialog
      open={open}
      size="sm"
      onOpenChange={setOpen}
      isPreventAutoFocusDialog={true}
      label={`${
        isEdit
          ? t('candidates:tabs:candidateOverview:languages:editLanguage')
          : t('candidates:tabs:candidateOverview:languages:addLanguage')
      }`}
      headingClassName="pb-4 tablet:pb-5">
      <LanguageForm
        setOpen={setOpen}
        defaultValue={defaultValue}
        onSubmitLanguage={onSubmitLanguage}
      />
    </Dialog>
  )
}

export default LanguageModal
