const QueryAddSubmitFeedbackJobInterviewKits = `
  mutation (
    $interviewId: Int!,
    $status: JobIkitStatus!,
    $ikitSessions: [JSON!],
    $note: String,
    $overallFeedback: IkitOverallFeedback,
    $jobIkitId: Int,
  ) {
    interviewIkitFeedbacksCreate(
      input: {
        interviewId: $interviewId,
        status: $status,
        ikitSessions: $ikitSessions,
        note: $note,
        overallFeedback: $overallFeedback,
        jobIkitId: $jobIkitId,
      }
    ) {
      jobIkitEvaluation {
        id
        name
        note
        guideline
        overallFeedback
        status
        jobIkitSessions {
          id
          name
          position
          jobIkitMetrics {
            id
            name
            score
          }
          jobIkitQuestions {
            id
            content
            answer
          }
        }
      }
    }
  }
`

export default QueryAddSubmitFeedbackJobInterviewKits
