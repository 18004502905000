import { useTranslation } from 'react-i18next'
import { useClassBasedTopSpace } from '~/components/Subscription/TopSpace'
import { Divider } from '~/core/ui/Divider'
import { TypographyH5 } from '~/core/ui/Heading'
import { Skeleton } from '~/core/ui/Skeleton'
import { cn } from '~/core/ui/utils'

const InterviewFeedbackSkeletonView = () => {
  const { t } = useTranslation()
  const calcHeight = useClassBasedTopSpace({
    34: 'min-h-[calc(100vh_-_91px)]',
    default: 'min-h-[calc(100vh_-_57px)]'
  })

  return (
    <>
      <div className="flex h-[56px] items-center justify-between px-6">
        <TypographyH5 className="font-medium text-gray-900">
          {t('interview:feedback_modal:title')}
        </TypographyH5>
      </div>
      <Divider />
      <div className={cn('flex w-full', calcHeight)}>
        <div className="w-[100%] space-y-2 border-l border-gray-100 px-6 pt-4">
          <Skeleton className="h-4 w-full rounded" />
          <Skeleton className="h-4 w-3/4 rounded" />
          <Skeleton className="h-4 w-2/4 rounded" />
        </div>
      </div>
    </>
  )
}

export default InterviewFeedbackSkeletonView
