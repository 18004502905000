import { StateCreator } from 'zustand'

export interface InterviewFeedbackSlice {
  openInterviewFeedbackDrawer: boolean
  setOpenInterviewFeedbackDrawer: (open: boolean) => void
  interviewDrawer?: {
    id?: string
    jobIkitEvalId?: string
    returnUrl?: string
    openForm: boolean
  }
  setInterviewDrawer: (params: {
    id?: string
    jobIkitEvalId?: string
    returnUrl?: string
    openForm: boolean
  }) => void
}

export const createInterviewFeedbackSlice: StateCreator<
  InterviewFeedbackSlice,
  [],
  [],
  InterviewFeedbackSlice
> = (set: Function) => ({
  openInterviewFeedbackDrawer: false,
  setOpenInterviewFeedbackDrawer: (open: boolean) =>
    set({ openInterviewFeedbackDrawer: open }),
  interviewDrawer: {
    id: undefined,
    jobIkitEvalId: undefined,
    returnUrl: undefined,
    openForm: false
  },
  setInterviewDrawer: (interviewDrawer: {
    id?: string
    jobIkitEvalId?: string
    returnUrl?: string
    openForm: boolean
  }) => set({ interviewDrawer })
})
