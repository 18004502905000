import { differenceInDays } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { isFeedbackDue } from '~/components/Calendar/CalendarView'
import { AvatarGroup } from '~/core/ui/AvatarGroup'
import { TypographyH5 } from '~/core/ui/Heading'
import IconWrapper from '~/core/ui/IconWrapper'
import { TypographyText } from '~/core/ui/Text'
import { cn } from '~/core/ui/utils'
import { getTimeZone } from '~/core/utilities/common'
import { defaultFormatDate, timeFormatDate } from '~/core/utilities/format-date'
import { InterviewFeedbackDetailProps } from '~/lib/features/calendar/types'
import { changeTimezone } from '~/lib/features/calendar/utilities/helper-schedule-interview'
import useBoundStore from '~/lib/store'

interface InterviewFeedbackHeaderViewProps {
  interviewsShow?: InterviewFeedbackDetailProps
}

const InterviewFeedbackHeaderView = (
  props: InterviewFeedbackHeaderViewProps
) => {
  const { t } = useTranslation()
  const { user } = useBoundStore()
  const { interviewsShow } = props
  if (!interviewsShow) return null

  const isFeedbackDueInterview = interviewsShow.fromDatetime
    ? isFeedbackDue({
        startTime: interviewsShow?.fromDatetime,
        hasFeedback: !!interviewsShow.ikitFeedbacksSummary?.length
      })
    : false

  const renderDueDate = (dueDate: string) => {
    const localeDate = new Date(dueDate).toLocaleDateString()
    const diffInDays = differenceInDays(
      localeDate ? new Date(localeDate) : new Date(),
      new Date().setHours(0, 0, 0, 0)
    )

    return dueDate ? (
      <div className="flex items-center">
        <TypographyText className="text-sm">
          {diffInDays === 0
            ? `${t('label:today')}`
            : diffInDays === 1
            ? `${t('label:tomorrow')}`
            : defaultFormatDate(dueDate ? new Date(dueDate) : new Date())}{' '}
        </TypographyText>
      </div>
    ) : null
  }

  return (
    <div className="flex h-[56px] items-center justify-between px-6 py-3">
      <TypographyH5 className="font-medium text-gray-900">
        {t('interview:feedback_modal:interviewFeedback')}
      </TypographyH5>

      <div
        className={cn(
          'flex min-w-[calc(38.6%_-_32px)] justify-between space-x-4 rounded px-3 py-1.5',
          isFeedbackDueInterview ? 'bg-ava-bg-50' : 'bg-blue-100'
        )}>
        <div className="flex items-center">
          <IconWrapper
            name="CalendarCheck2"
            size={16}
            className={
              isFeedbackDueInterview ? 'text-red-800' : 'text-blue-800'
            }
          />
          <div
            className={cn(
              'ml-2 text-sm font-medium',
              isFeedbackDueInterview ? 'text-red-800' : 'text-blue-800'
            )}>
            {interviewsShow.eventTypeDescription}
          </div>
          <span
            className={cn(
              'mx-2 h-0.5 w-0.5 rounded',
              isFeedbackDueInterview ? 'bg-red-800' : 'bg-blue-800'
            )}
          />
          <div
            className={cn(
              'flex space-x-1 text-sm font-medium',
              isFeedbackDueInterview ? 'text-red-800' : 'text-blue-800'
            )}>
            {interviewsShow?.fromDatetime
              ? renderDueDate(interviewsShow.fromDatetime)
              : ''}{' '}
            <span>
              {interviewsShow?.fromDatetime
                ? timeFormatDate(
                    changeTimezone({
                      date: interviewsShow?.fromDatetime,
                      timezone: user?.timezone
                    })
                  )
                : null}
              {interviewsShow?.toDatetime
                ? ` (GMT${getTimeZone(interviewsShow.toDatetime)})`
                : null}
            </span>
          </div>
        </div>
        {interviewsShow?.attendees?.length && (
          <div className="flex items-center space-x-4">
            <AvatarGroup
              toolTipPosition="left"
              tooltipAlign="start"
              size="2xs"
              className="-space-x-1"
              source={interviewsShow.attendees.map((item) => ({
                id: Number(item.id),
                alt: item.fullName,
                src: item.avatarVariants?.thumb?.url,
                tooltip: (
                  <>
                    {item.fullName}
                    <div>{item.email}</div>
                  </>
                ),
                defaultColour: item.defaultColour
              }))}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default InterviewFeedbackHeaderView
