import { gql } from 'urql'
import { ILogoAndAvatarVariants } from '~/core/@types/global'

const QueryTenantsList = gql<
  {
    tenantsList: {
      collection: {
        id: number
        name: string
        logoVariants?: ILogoAndAvatarVariants
        totalMembers: number
        blocked: boolean
        members: {
          id: number
          fullName: string
          avatarVariants?: ILogoAndAvatarVariants
          defaultColour: string
        }[]
      }[]
      metadata: { totalCount: number }
    }
  },
  {}
>`
  query ($limit: Int, $page: Int) {
    tenantsList(limit: $limit, page: $page) {
      collection {
        id
        name
        logoVariants
        totalMembers
        blocked
        members {
          id
          fullName
          avatarVariants
          defaultColour
        }
      }
      metadata {
        totalCount
      }
    }
  }
`

export default QueryTenantsList
