import { StateCreator } from 'zustand'

export interface ApplicantFeedbackSlice {
  openApplicantFeedbackDrawer: boolean
  setOpenApplicantFeedbackDrawer: (open: boolean) => void
  applicantDrawer?: {
    id?: string
    jobIkitEvalId?: string
    returnUrl?: string
    openForm: boolean
  }
  setApplicantDrawer: (params: {
    id?: string
    jobIkitEvalId?: string
    returnUrl?: string
    openForm: boolean
  }) => void
}

export const createApplicantFeedbackSlice: StateCreator<
  ApplicantFeedbackSlice,
  [],
  [],
  ApplicantFeedbackSlice
> = (set: Function) => ({
  openApplicantFeedbackDrawer: false,
  setOpenApplicantFeedbackDrawer: (open: boolean) =>
    set({ openApplicantFeedbackDrawer: open }),
  applicantDrawer: {
    id: undefined,
    jobIkitEvalId: undefined,
    returnUrl: undefined,
    openForm: false
  },
  setApplicantDrawer: (applicantDrawer: {
    id?: string
    jobIkitEvalId?: string
    returnUrl?: string
    openForm: boolean
  }) => set({ applicantDrawer })
})
