import { createContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import SkeletonContainer from '~/components/Skeleton'
import { useClassBasedTopSpace } from '~/components/Subscription/TopSpace'
import { IRouterWithID } from '~/core/@types/global'
import useQueryGraphQL from '~/core/middleware/use-query-graphQL'
import { Container } from '~/core/ui/Container'
import { Divider } from '~/core/ui/Divider'
import { cn } from '~/core/ui/utils'
import {
  DEFAULT_PERMISSIONS_LIST,
  IDefaultPermissionsList
} from '~/core/utilities/feature-permission'
import QueryInterviewFeedbackDetail from '~/lib/features/calendar/graphql/query-interviews-feedback-detail'
import useInterviewStore from '~/lib/features/interviews/store'
import usePermissionJob from '~/lib/features/permissions/hooks/use-permission-job'
import InterviewFeedbackHeaderView from './InterviewFeedbackHeaderView'
import InterviewFeedbackLeftView from './InterviewFeedbackLeftView'
import InterviewFeedbackRightView from './InterviewFeedbackRightView'
import InterviewFeedbackSkeletonView from './InterviewFeedbackSkeletonView'

export const InterviewFeedbackPermissionContext =
  createContext<IDefaultPermissionsList>(DEFAULT_PERMISSIONS_LIST)

interface InterviewFeedbackProps {
  isDrawer: boolean
  id?: IRouterWithID
  onClose?: () => void
}

const InterviewFeedbackView = (props: InterviewFeedbackProps) => {
  const { t } = useTranslation()
  const { isDrawer, id, onClose } = props
  const { interviewDrawer } = useInterviewStore()
  const { actionJobIkitEvaluation } = usePermissionJob()
  const {
    isLoading,
    data: interview,
    trigger: triggerInterviewFeedback
  } = useQueryGraphQL({
    query: QueryInterviewFeedbackDetail,
    variables: {
      id: Number(id),
      jobIkitEvalId: interviewDrawer?.jobIkitEvalId
        ? Number(interviewDrawer?.jobIkitEvalId)
        : undefined
    },
    shouldPause: true
  })
  const interviewsShow = interview?.interviewsEvaluationShow

  useEffect(() => {
    if (id) {
      triggerInterviewFeedback()
    }
  }, [id])

  useEffect(() => {
    window.addEventListener('popstate', function (e) {
      window.location.reload()
    })
  }, [])

  const calcHeight = useClassBasedTopSpace({
    34:
      isDrawer === false
        ? 'min-h-[calc(100vh_-_91px)]'
        : 'min-h-[calc(100vh_-_57px)]',
    default: 'min-h-[calc(100vh_-_57px)]'
  })

  return (
    <InterviewFeedbackPermissionContext.Provider
      value={actionJobIkitEvaluation}>
      <Container>
        <SkeletonContainer
          showMoreLabel={`${t('common:infinity:showMore')}`}
          useLoading={false}
          classNameFirstLoading="space-y-0"
          isFirstLoading={interview === undefined && (isLoading || !isLoading)}
          renderCustomSkeleton={<InterviewFeedbackSkeletonView />}>
          <InterviewFeedbackHeaderView interviewsShow={interviewsShow} />
          <Divider />
          <div className={cn('flex w-full', calcHeight)}>
            <InterviewFeedbackLeftView
              isDrawer={isDrawer}
              interviewsShow={interviewsShow}
            />
            <InterviewFeedbackRightView
              isDrawer={isDrawer}
              interviewId={id}
              interviewsShow={interviewsShow}
              callback={triggerInterviewFeedback}
              onClose={onClose}
              // anyChangesForm={anyChangesForm}
              // setAnyChangesFrom={setAnyChangesFrom}
            />
          </div>
        </SkeletonContainer>
      </Container>
    </InterviewFeedbackPermissionContext.Provider>
  )
}

export default InterviewFeedbackView
