import { EventChangeArg, EventContentArg } from '@fullcalendar/core'
import { differenceInMinutes, formatISO } from 'date-fns'
import {
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useCallback,
  useMemo
} from 'react'
import { useTranslation } from 'react-i18next'
import { Calendar } from '~/core/ui/FullCalendar'
import { TextButton } from '~/core/ui/TextButton'
import { cn } from '~/core/ui/utils'
import { timeFormatDate } from '~/core/utilities/format-date'
import { EditModalProps } from '~/lib/features/calendar/hooks/use-schedule-interview-calendar'
import {
  ActionInterviewManagement,
  FilterControlType,
  InterviewDetailType,
  InterviewsListControlType
} from '~/lib/features/calendar/types'
import { INTERVIEW_STATUS_COLOR } from '~/lib/features/calendar/utilities/enum.cva'
import {
  addTzToDate,
  changeTimezone,
  removeTzFromDate
} from '~/lib/features/calendar/utilities/helper-schedule-interview'
import usePermissionJob from '~/lib/features/permissions/hooks/use-permission-job'
import useBoundStore from '~/lib/store'
import { useClassBasedTopSpace } from '../Subscription/TopSpace'
import CalendarPopupDetail from './CalendarPopupDetail'

export const isFeedbackDue = ({
  startTime,
  now,
  hasFeedback
}: {
  startTime: string | Date
  now?: string | Date
  hasFeedback: boolean
}) => {
  return (
    !hasFeedback &&
    differenceInMinutes(new Date(startTime), now ? new Date(now) : new Date()) <
      0
  )
}

const CalendarView: FC<{
  filterControl?: FilterControlType
  interviewsListControl: InterviewsListControlType
  action: ActionInterviewManagement
  interviewEdit?: {
    data: InterviewDetailType | undefined
    fetchData: () => any
  }
  setEditModalInterview: Dispatch<SetStateAction<EditModalProps>>
}> = ({
  filterControl,
  interviewsListControl,
  action,
  interviewEdit,
  setEditModalInterview
}) => {
  const { t, i18n } = useTranslation()
  const { user } = useBoundStore()
  const { actionInterview } = usePermissionJob()

  const now = useMemo(
    () => changeTimezone({ date: new Date(), timezone: user.timezone }),
    [user.timezone]
  )

  const renderEvent = useCallback<
    (eventInfo: EventContentArg, isOpenPopoverDetail: boolean) => ReactNode
  >(
    (e, isOpenPopoverDetail) => {
      const fromDate =
        e.event.start && user?.timezone
          ? addTzToDate(formatISO(e.event.start), user?.timezone)
          : e.event._def.extendedProps?.fromDatetime
      const isFeedbackDueInterview = fromDate
        ? isFeedbackDue({
            startTime: fromDate,
            hasFeedback:
              !!e.event._def.extendedProps?.ikitFeedbacksSummary?.length
          })
        : false
      return (
        <div className="h-full w-full rounded border border-solid border-white">
          <div
            className={`group flex h-full items-start rounded border-l-2 ${
              isFeedbackDueInterview
                ? INTERVIEW_STATUS_COLOR.red.borderColor
                : INTERVIEW_STATUS_COLOR.blue.borderColor
            } ${
              isOpenPopoverDetail
                ? isFeedbackDueInterview
                  ? INTERVIEW_STATUS_COLOR.red.bgActiveColor
                  : INTERVIEW_STATUS_COLOR.blue.bgActiveColor
                : isFeedbackDueInterview
                ? INTERVIEW_STATUS_COLOR.red.bgColor
                : INTERVIEW_STATUS_COLOR.blue.bgColor
            } ${
              isFeedbackDueInterview
                ? INTERVIEW_STATUS_COLOR.red.hoverBgActiveColor
                : INTERVIEW_STATUS_COLOR.blue.hoverBgActiveColor
            } px-2 py-0.5`}>
            {e.event?.start && (
              <span
                className={`mr-1 text-xs ${
                  isOpenPopoverDetail
                    ? INTERVIEW_STATUS_COLOR.blue.textActiveColor
                    : isFeedbackDueInterview
                    ? INTERVIEW_STATUS_COLOR.red.textColor
                    : INTERVIEW_STATUS_COLOR.blue.textColor
                } ${
                  isFeedbackDueInterview
                    ? INTERVIEW_STATUS_COLOR.red.hoverTextActiveColor
                    : INTERVIEW_STATUS_COLOR.blue.hoverTextActiveColor
                }`}>
                {timeFormatDate(e.event?.start)}
              </span>
            )}
            <span
              className={`truncate text-xs ${
                isOpenPopoverDetail
                  ? INTERVIEW_STATUS_COLOR.blue.textActiveColor
                  : isFeedbackDueInterview
                  ? INTERVIEW_STATUS_COLOR.red.textColor
                  : INTERVIEW_STATUS_COLOR.blue.textColor
              } ${
                isFeedbackDueInterview
                  ? INTERVIEW_STATUS_COLOR.red.hoverTextActiveColor
                  : INTERVIEW_STATUS_COLOR.blue.hoverTextActiveColor
              }`}>
              {e.event._def.title}
            </span>
          </div>
        </div>
      )
    },
    [now, user]
  )

  const renderEventPopupDetail = useCallback<
    (
      eventInfo: EventContentArg,
      closeEvent?: (() => void) | undefined
    ) => ReactNode
  >(
    (e, closeEvent) => {
      const closePopup = () => {
        closeEvent && closeEvent()
      }

      return (
        <CalendarPopupDetail
          eventInfo={e}
          closeEvent={closePopup}
          refetchCalendar={interviewsListControl?.refetch}
          updateInterview={action.interviewUpdateAction.updateInterview}
          setEditModalInterview={setEditModalInterview}
        />
      )
    },
    [interviewsListControl, action.interviewUpdateAction]
  )

  const onHandleChangeEvent = useCallback(
    (e: EventChangeArg) => {
      const changedEvent = {
        ...(e.event.start && user.timezone
          ? {
              fromDatetime: addTzToDate(formatISO(e.event.start), user.timezone)
            }
          : {}),
        ...(e.event.end && user.timezone
          ? {
              toDatetime: addTzToDate(formatISO(e.event.end), user.timezone)
            }
          : {})
      }
      setEditModalInterview({
        open: true,
        interviewId: e.event.extendedProps.id,
        newDataInterview: changedEvent,
        scheduleCallback: () => {
          interviewsListControl.refetch()
        }
      })

      return Promise.resolve()
    },
    [user.timezone]
  )

  const topSpace = useClassBasedTopSpace({
    34: 'h-[calc(100vh_-_90px)] tablet:w-[calc(100vw_-_95px)]',
    default: 'h-[calc(100vh_-_56px)] tablet:w-[calc(100vw_-_61px)]'
  })
  const height = useClassBasedTopSpace({
    34: 'calc(100vh - 90px)',
    default: 'calc(100vh - 56px)'
  })

  return (
    <div className={cn(topSpace, 'bg-white tablet:w-[calc(100vw_-_61px)]')}>
      <Calendar
        locale={i18n.language === 'ja' ? 'ja' : 'en-GB'}
        textOverride={{
          today: `${t('label:today')}`,
          month: `${t('label:placeholder:month')}`,
          week: `${t('label:placeholder:week')}`
        }}
        editable={actionInterview.update}
        selectable={false}
        now={now}
        eventMinHeight={25}
        isFetchingEvents={interviewsListControl?.isLoading}
        dataTransform={
          interviewsListControl?.data?.interviewsList.collection &&
          interviewsListControl?.data?.interviewsList.collection?.length > 0
            ? interviewsListControl?.data?.interviewsList.collection?.map(
                (interview) => ({
                  title: interview.profile.fullName,
                  start: removeTzFromDate(interview.fromDatetime),
                  end: removeTzFromDate(interview.toDatetime),
                  // classNames: ['!index-auto'],
                  tabIndex: 'auto',
                  extendedProps: {
                    ...interview
                  }
                })
              )
            : []
        }
        height={height}
        renderEventContent={renderEvent}
        renderEventContentTimeGrid={renderEvent}
        renderEventPopoverContent={renderEventPopupDetail}
        renderEventPopoverContentTimeGrid={renderEventPopupDetail}
        renderMoreLinkContent={({ num }) => (
          <TextButton
            type="secondary"
            size="sm"
            iconMenus="Plus"
            underline={false}
            label={`${t('common:numberMore', { number: num })}`}
          />
        )}
        renderMoreLinkContentTimeGrid={({ num }) => (
          <TextButton
            type="secondary"
            size="sm"
            iconMenus="Plus"
            underline={false}
            label={num}
          />
        )}
        setValueSearch={({ from_date, to_date }) => {
          from_date &&
            to_date &&
            user?.timezone &&
            filterControl?.onChange({
              attendeeIds: filterControl?.value?.attendeeIds,
              fromDatetime: addTzToDate(
                `${from_date}T00:00:00`,
                user?.timezone
              ),
              toDatetime: addTzToDate(`${to_date}T23:59:59`, user?.timezone)
            })
        }}
        onChangeEvent={onHandleChangeEvent}
      />
    </div>
  )
}

export default CalendarView
