import { TFunction } from 'i18next'
import { z } from 'zod'

export const schemaLanguageProfile = (t: TFunction) => {
  return z.object({
    language: z
      .object({
        value: z.string(),
        supportingObj: z.object({ name: z.string() })
      })
      .nullish()
      .refine((obj) => !!obj?.value, {
        message: `${t('form:requiredField')}`
      }),
    proficiency: z
      .object({
        value: z.string(),
        supportingObj: z.object({ name: z.string() })
      })
      .nullish()
      .optional()
  })
}
