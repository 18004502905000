const MutationDeleteInterview = `
  mutation ($id: Int!, $sendEmail: Boolean) {
    interviewsDelete(
      input: {
        id: $id,
        sendEmail: $sendEmail
      }
    ) {
      success 
    }
  }
`

export default MutationDeleteInterview
