import { Router, useRouter } from 'next/router'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { openAlert } from '~/core/ui/AlertDialog'

export const useDiscardRouteChange = (callback?: () => void) => {
  const { t } = useTranslation()
  const router = useRouter()
  const [anyChangesForm, setAnyChangesFrom] = useState(false)

  const ref = useRef(anyChangesForm)
  ref.current = anyChangesForm

  useEffect(() => {
    Router.events.on('routeChangeStart', handleRouteChange)

    return () => {
      Router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [])

  const handleRouteChange = (url: string) => {
    if (ref.current) {
      openAlert({
        isPreventAutoFocusDialog: false,
        className: 'w-[480px]',
        title: `${t('common:modal:discard_unsaved_changes_title')}`,
        description: t('common:modal:discard_unsaved_changes_description'),
        actions: [
          {
            label: `${t('button:keepEditing')}`,
            type: 'secondary',
            size: 'sm'
          },
          {
            label: `${t('button:discard')}`,
            type: 'destructive',
            size: 'sm',
            onClick: async () => {
              callback && callback()
              setAnyChangesFrom(false)
              router.push(url)
            }
          }
        ]
      })

      throw 'Abort route change due to unsaved changes in form.'
    }
  }

  return {
    anyChangesForm,
    setAnyChangesFrom
  }
}
