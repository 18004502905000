import {
  ACTIONS_PERMISSIONS,
  canAccessFeature,
  canActionFeature,
  PERMISSIONS_LIST
} from '~/core/utilities/feature-permission'
import useBoundStore from '~/lib/store'

const usePermissionPlacement = () => {
  const { currentRole, permissionSetting } = useBoundStore()

  const canAccessPage = canAccessFeature({
    currentRole,
    permissionSetting,
    keyModule: [PERMISSIONS_LIST.placement.keyModule],
    keyModuleObject: [
      PERMISSIONS_LIST.placement.objects.placement.keyModuleObject
    ],
    action: ACTIONS_PERMISSIONS.show
  })

  const canAccessProfitSplit = canAccessFeature({
    currentRole,
    permissionSetting,
    keyModule: [PERMISSIONS_LIST.placement.keyModule],
    keyModuleObject: [
      PERMISSIONS_LIST.placement.objects.profit_split.keyModuleObject
    ],
    action: ACTIONS_PERMISSIONS.show
  })

  const actionPlacement = canActionFeature({
    currentRole,
    permissionSetting,
    keyModule: [PERMISSIONS_LIST.placement.keyModule],
    keyModuleObject: [
      PERMISSIONS_LIST.placement.objects.placement.keyModuleObject
    ]
  })

  const actionProfitSplit = canActionFeature({
    currentRole,
    permissionSetting,
    keyModule: [PERMISSIONS_LIST.placement.keyModule],
    keyModuleObject: [
      PERMISSIONS_LIST.placement.objects.profit_split.keyModuleObject
    ]
  })

  return {
    canAccessPage,
    canAccessProfitSplit,
    actionPlacement,
    actionProfitSplit
  }
}

export default usePermissionPlacement
