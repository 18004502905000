import { create } from 'zustand'
import {
  createInterviewFeedbackSlice,
  InterviewFeedbackSlice
} from './open-interview-feedback-drawer-slice'

const useInterviewStore = create<InterviewFeedbackSlice>()((...a) => ({
  ...createInterviewFeedbackSlice(...a)
}))

export default useInterviewStore
