'use client'

import * as SheetPrimitive from '@radix-ui/react-dialog'
import { cva, type VariantProps } from 'class-variance-authority'
import * as React from 'react'
import { useCallback } from 'react'
import { Badge } from '~/core/ui/Badge'
import { Button, ButtonProps } from '~/core/ui/Button'
import { CloseButton } from '~/core/ui/CloseButton'
import { TypographyText } from '~/core/ui/Text'
import { TextButton, TextButtonProps } from '~/core/ui/TextButton'
import { cn } from '~/core/ui/utils'
import { Tooltip } from './Tooltip'

const Sheet = SheetPrimitive.Root

const SheetTrigger = SheetPrimitive.Trigger

const SheetClose = SheetPrimitive.Close

const portalVariants = cva('fixed right-0 top-0 z-50 flex', {
  variants: {
    position: {
      top: 'items-start',
      bottom: 'items-end',
      left: 'justify-start',
      right: 'justify-end'
    }
  },
  defaultVariants: { position: 'right' }
})

interface SheetPortalProps
  extends SheetPrimitive.DialogPortalProps,
    VariantProps<typeof portalVariants> {}

const SheetPortal = ({
  position,
  className,
  children,
  ...props
}: SheetPortalProps) => (
  <SheetPrimitive.Portal className={cn(className)} {...props}>
    <div className={portalVariants({ position })}>{children}</div>
  </SheetPrimitive.Portal>
)
SheetPortal.displayName = SheetPrimitive.Portal.displayName

const SheetOverlay = React.forwardRef<
  React.ElementRef<typeof SheetPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof SheetPrimitive.Overlay>
>(({ className, children, ...props }, ref) => (
  <SheetPrimitive.Overlay
    className={cn(
      'data-[state=closed]:animate-out data-[state=closed]:fade-out data-[state=open]:fade-in fixed inset-0 z-50 bg-gray-1000/60 transition-all duration-100',
      className
    )}
    {...props}
    ref={ref}
  />
))
SheetOverlay.displayName = SheetPrimitive.Overlay.displayName

const sheetVariants = cva(
  'fixed z-50 scale-100 bg-white opacity-100 shadow-lg border',
  {
    variants: {
      position: {
        top: 'animate-in slide-in-from-top w-full duration-300',
        bottom: 'animate-in slide-in-from-bottom w-full duration-300',
        left: 'animate-from-left translate-x-0 h-full duration-300',
        right: 'animate-in translate-x-0 h-full duration-300'
      },
      size: {
        content: '',
        default: '',
        sm: '',
        lg: '',
        xl: '',
        full: ''
      }
    },
    compoundVariants: [
      {
        position: ['right', 'left'],
        size: 'content',
        class: 'max-w-screen'
      },
      {
        position: ['right', 'left'],
        size: 'default',
        class: 'w-full desktop:w-1/3'
      },
      {
        position: ['right', 'left'],
        size: 'sm',
        class: 'w-full desktop:w-1/4'
      },
      {
        position: ['right', 'left'],
        size: 'lg',
        class: 'w-full desktop:w-1/2'
      },
      {
        position: ['right', 'left'],
        size: 'xl',
        class: 'w-full desktop:w-5/6'
      },
      {
        position: ['right', 'left'],
        size: 'full',
        class: 'w-screen'
      }
    ],
    defaultVariants: {
      position: 'right',
      size: 'default'
    }
  }
)

interface DialogContentProps
  extends React.ComponentPropsWithoutRef<typeof SheetPrimitive.Content>,
    VariantProps<typeof sheetVariants> {
  onHandleEscapeKeyDown?: () => void
}

const SheetContent = React.forwardRef<
  React.ElementRef<typeof SheetPrimitive.Content>,
  DialogContentProps
>(
  (
    { position, size, className, onHandleEscapeKeyDown, children, ...props },
    ref
  ) => (
    <SheetPortal position={position} >

      <SheetPrimitive.Content
        tabIndex={undefined}
        ref={ref}
        className={cn(sheetVariants({ position, size }), className)}
        onEscapeKeyDown={(e) => {
          const target = e?.target as HTMLElement

          const tagName = target?.tagName
          const ProseMirrorContent =
            target?.className.search('ProseMirror') >= 0
          const elm = document.getElementsByClassName('clickable-blur')?.[0]

          if (['INPUT', 'TEXTAREA'].includes(tagName) || ProseMirrorContent) {
            target?.blur()

            if (elm) {
              const mouseClickEvents = ['mousedown', 'click', 'mouseup']

              mouseClickEvents.forEach((mouseEventType) =>
                elm.dispatchEvent(
                  new MouseEvent(mouseEventType, {
                    view: window,
                    bubbles: true,
                    cancelable: true,
                    buttons: 1
                  })
                )
              )
            }
          } else {
            onHandleEscapeKeyDown && onHandleEscapeKeyDown()
          }
        }}
        {...props}>
        {children}
      </SheetPrimitive.Content>
    </SheetPortal>
  )
)
SheetContent.displayName = SheetPrimitive.Content.displayName

const SheetHeader = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn('border-b border-b-gray-100 px-6 py-[15px]', className)}
    {...props}
  />
)
SheetHeader.displayName = 'SheetHeader'

const SheetFooter = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      'flex items-center justify-between border-t border-t-gray-100 px-6 py-4',
      className
    )}
    {...props}
  />
)
SheetFooter.displayName = 'SheetFooter'

const SheetTitle = React.forwardRef<
  React.ElementRef<typeof SheetPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof SheetPrimitive.Title>
>(({ className, ...props }, ref) => (
  <SheetPrimitive.Title
    ref={ref}
    className={cn('text-foreground text-lg font-semibold', className)}
    {...props}
  />
))
SheetTitle.displayName = SheetPrimitive.Title.displayName

const SheetDescription = React.forwardRef<
  React.ElementRef<typeof SheetPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof SheetPrimitive.Description>
>(({ className, ...props }, ref) => (
  <SheetPrimitive.Description
    ref={ref}
    className={cn('text-muted-foreground text-sm', className)}
    {...props}
  />
))
SheetDescription.displayName = SheetPrimitive.Description.displayName

type FCC<Props = Record<string, unknown>> = React.FC<
  React.PropsWithChildren<Props>
>

interface DrawerProps {
  open?: boolean
  onClose?: (event: React.MouseEvent<HTMLElement>) => void
  onEscapeKeyDown?: () => void
  customCloseButton?: React.ReactNode
  configHeader?: {
    title?: string
    description?: string
    count?: number
  }
  configFooter?: {
    additionTextButton?: TextButtonProps
    actions: Array<ButtonProps & { tooltipText?: string }>
  }
  contentRef?: React.Ref<HTMLDivElement>
  position?: 'left' | 'right'
  drawerClassName?: string
  size?: 'content' | 'default' | 'sm' | 'lg' | 'xl' | 'full'
}

const DrawerWithoutOverlay: FCC<DrawerProps> = ({
  open,
  onClose,
  onEscapeKeyDown,
  customCloseButton,
  configHeader,
  configFooter,
  children,
  contentRef,
  position = 'right',
  drawerClassName,
  size = 'default'
}) => {
  const preventAutoFocus = useCallback((e: Event) => {
    e.preventDefault()
  }, [])
  return (
    <Sheet open={open} modal={false}>
      <SheetContent
        size={size}
        ref={contentRef}
        position={position}
        className={cn(
          'flex max-w-[503px] flex-col justify-between',
          drawerClassName
        )}
        onHandleEscapeKeyDown={onEscapeKeyDown}
        onOpenAutoFocus={preventAutoFocus}>
        <div>
          {configHeader && Object.keys(configHeader).length > 0 && (
            <SheetHeader>
              <div className="flex items-center space-x-2">
                {configHeader?.title && (
                  <TypographyText className="text-lg font-medium text-gray-900">
                    {configHeader?.title}
                  </TypographyText>
                )}
                {(configHeader?.count || configHeader?.count === 0) && (
                  <Badge color="gray" size="md" radius="circular">
                    {configHeader?.count}
                  </Badge>
                )}
              </div>

              {configHeader?.description && (
                <TypographyText className="text-sm text-gray-600">
                  {configHeader?.description}
                </TypographyText>
              )}
            </SheetHeader>
          )}
          {children}
        </div>
        {configFooter && Object.keys(configFooter).length > 0 && (
          <SheetFooter>
            <div>
              {configFooter?.additionTextButton &&
                Object.keys(configFooter?.additionTextButton) && (
                  <TextButton {...configFooter?.additionTextButton} />
                )}
            </div>
            <div className="flex items-center">
              {configFooter?.actions &&
                configFooter?.actions.map((actionButtonProps, index) => {
                  if (actionButtonProps.tooltipText) {
                    return (
                      <Tooltip
                        classNameAsChild={actionButtonProps.className}
                        key={`drawer-action-button-${index}`}
                        content={actionButtonProps.tooltipText}>
                        <Button {...actionButtonProps} className="" />
                      </Tooltip>
                    )
                  }

                  return (
                    <Button
                      key={`drawer-action-button-${index}`}
                      {...actionButtonProps}
                    />
                  )
                })}
            </div>
          </SheetFooter>
        )}

        <div id="drawer-append-container" className="z-[1234567890]" />
      </SheetContent>
    </Sheet>
  )
}

export {
  DrawerWithoutOverlay,
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger
}
export type { DialogContentProps, DrawerProps }
