const QueryUpdateSubmitFeedbackJobInterviewKits = `
  mutation (
    $id: Int!,
    $status: JobIkitStatus!,
    $ikitSessions: [JSON!],
    $note: String,
    $overallFeedback: IkitOverallFeedback,
  ) {
    interviewIkitFeedbacksUpdate(
      input: {
        id: $id,
        status: $status,
        ikitSessions: $ikitSessions,
        note: $note,
        overallFeedback: $overallFeedback,
      }
    ) {
      jobIkitEvaluation {
        id
        name
        note
        guideline
        overallFeedback
        status
        jobIkitSessions {
          id
          name
          position
          jobIkitMetrics {
            id
            name
            score
          }
          jobIkitQuestions {
            id
            content
            answer
          }
        }
      }
    }
  }
`

export default QueryUpdateSubmitFeedbackJobInterviewKits
