import { useEffect } from 'react'
import SkeletonContainer from '~/components/Skeleton'
import { useClassBasedTopSpace } from '~/components/Subscription/TopSpace'
import { IRouterWithID } from '~/core/@types/global'
import useQueryGraphQL from '~/core/middleware/use-query-graphQL'
import { Container } from '~/core/ui/Container'
import { Divider } from '~/core/ui/Divider'
import { cn } from '~/core/ui/utils'
import ApplicantFeedbackHeaderView from './ApplicantFeedbackHeaderView'
import ApplicantFeedbackRightView from './ApplicantFeedbackRightView'
import ApplicantFeedbackSkeletonView from './ApplicantFeedbackSkeletonView'

import useApplicantFeedbackStore from '~/lib/features/applicant-feedback/store'
import QueryCandidateApplicantFeedbackDetail from '~/lib/features/candidates/graphql/query-candidate-applicant-feedback-detail'
import useCandidateStore from '~/lib/features/candidates/store'
import { useTranslation } from 'react-i18next'

interface ApplicantFeedbackViewProps {
  isDrawer: boolean
  id?: IRouterWithID
  onClose?: () => void
}

const ApplicantFeedbackView = (props: ApplicantFeedbackViewProps) => {
  const { t } = useTranslation()
  const { isDrawer, id, onClose } = props
  const { applicantDrawer } = useApplicantFeedbackStore()
  const { setIsRefetchActivitiesList } = useCandidateStore()
  const {
    isLoading,
    data,
    trigger: fetchCandidateApplicantDetail
  } = useQueryGraphQL({
    query: QueryCandidateApplicantFeedbackDetail,
    variables: {
      id: id ? Number(id) : Number(applicantDrawer?.id),
      jobIkitEvalId: Number(applicantDrawer?.jobIkitEvalId)
    },
    shouldPause: true
  })

  const applicantData = data?.applicantsShow
  useEffect(() => {
    fetchCandidateApplicantDetail()
  }, [id, applicantDrawer?.jobIkitEvalId, applicantDrawer?.id])

  useEffect(() => {
    window.addEventListener('popstate', function (e) {
      window.location.reload()
    })
  }, [])

  const calcHeight = useClassBasedTopSpace({
    34:
      isDrawer === false
        ? 'min-h-[calc(100vh_-_91px)]'
        : 'min-h-[calc(100vh_-_2px)]',
    default: 'min-h-[calc(100vh_-_2px)]'
  })

  return (
    <>
      <div className={cn('flex w-full', calcHeight)}>
        <SkeletonContainer
          showMoreLabel={`${t('common:infinity:showMore')}`}
          useLoading={false}
          classNameFirstLoading="space-y-0 w-full"
          isFirstLoading={isLoading}
          renderCustomSkeleton={<ApplicantFeedbackSkeletonView />}>
          <ApplicantFeedbackRightView
            isDrawer={isDrawer}
            applicantShow={applicantData}
            callback={() => {
              fetchCandidateApplicantDetail()
              setIsRefetchActivitiesList(true)
            }}
            onClose={onClose}
          />
        </SkeletonContainer>
      </div>
    </>
  )
}

export default ApplicantFeedbackView
