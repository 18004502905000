const DeleteTaskMutation = `
  mutation (
    $id: Int!
    $profileId: Int
  ) {
    tasksDelete(
      input: {
        id: $id,
        profileId: $profileId
      }
    ) {
        success
    }
  }
`

export default DeleteTaskMutation
