import { createContext, FC, useContext, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import ApplicantFeedbackView from '~/components/ApplicantFeedback/ApplicantFeedbackView'
import { openAlert } from '~/core/ui/AlertDialog'
import { Drawer } from '~/core/ui/Drawer'
import { DrawerWithoutOverlay } from '~/core/ui/DrawerWithoutOverlay'
import { pushStateBrowser } from '~/core/utilities/is-browser'
import useApplicantFeedbackStore from '~/lib/features/applicant-feedback/store'
import useCandidateStore from '~/lib/features/candidates/store'
import { useDiscardRouteChange } from '~/lib/hooks/use-discard-route-change'
import useBoundStore from '~/lib/store'

const ApplicantFeedbackDrawer: FC = () => {
  const { t } = useTranslation()
  const {
    openApplicantFeedbackDrawer,
    setOpenApplicantFeedbackDrawer,
    applicantDrawer,
    setApplicantDrawer
  } = useApplicantFeedbackStore()
  const { setRefetchMyList } = useBoundStore()
  const { anyChangesForm, setAnyChangesFrom } = useDiscardRouteChange()
  const { setIsRefetchActivitiesList } = useCandidateStore()
  const handleOnClose = () => {
    if (anyChangesForm) {
      openAlert({
        isPreventAutoFocusDialog: false,
        className: 'w-[480px]',
        title: `${t('common:modal:discard_unsaved_changes_title')}`,
        description: `${t('common:modal:discard_unsaved_changes_description')}`,
        actions: [
          {
            label: `${t('button:keepEditing')}`,
            type: 'secondary',
            size: 'sm'
          },
          {
            label: `${t('button:discard')}`,
            type: 'destructive',
            size: 'sm',
            onClick: () => handleActionClose()
          }
        ]
      })
    } else {
      handleActionClose()
    }
  }

  const handleActionClose = () => {
    setAnyChangesFrom(false)
    setRefetchMyList(true, ['job-kanban'])
    setOpenApplicantFeedbackDrawer(false)
    setIsRefetchActivitiesList(true)
    if (applicantDrawer?.returnUrl) {
      pushStateBrowser({
        state: {},
        unused: '',
        url: applicantDrawer.returnUrl
      })
    }
    setApplicantDrawer({
      id: undefined,
      returnUrl: undefined,
      openForm: false,
      jobIkitEvalId: undefined
    })
  }

  const drawerContainerRef = useRef<HTMLDivElement>(null)
  return (
    <ApplicantFeedbackContext.Provider value={{ drawerContainerRef }}>
      <DrawerWithoutOverlay
        position="right"
        size="full"
        drawerClassName="w-[38.6%]"
        contentRef={drawerContainerRef}
        open={openApplicantFeedbackDrawer}
        onClose={handleOnClose}
        onEscapeKeyDown={handleOnClose}>
        <ApplicantFeedbackView
          isDrawer
          id={applicantDrawer?.id}
          onClose={handleOnClose}
        />
      </DrawerWithoutOverlay>
    </ApplicantFeedbackContext.Provider>
  )
}

export const useGetUploadDrawerRef = () => {
  const uploadContext = useContext(ApplicantFeedbackContext)
  return uploadContext.drawerContainerRef?.current
}

const ApplicantFeedbackContext = createContext<{
  drawerContainerRef?: React.RefObject<HTMLDivElement>
}>({})

export default ApplicantFeedbackDrawer
