import { FC } from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ISelectOption } from '~/core/@types/global'
import { Button } from '~/core/ui/Button'
import { DynamicImportForm } from '~/core/ui/DynamicImportForm'
import { FormControlItem } from '~/core/ui/FormControlItem'
import { NativeSelect } from '~/core/ui/NativeSelect'
import useCandidateProfile from '~/lib/features/candidates/hooks/use-query-candidate'
import { schemaLanguageProfile } from '~/lib/features/candidates/schema/validation-language-profile'

const LanguageForm: FC<{
  defaultValue?: {
    language?: ISelectOption
    proficiency?: ISelectOption
  }
  onSubmitLanguage: (value: {
    index: number
    language: ISelectOption
    proficiency: ISelectOption
  }) => Promise<void>
  setOpen: (open: boolean) => void
}> = ({ defaultValue, onSubmitLanguage, setOpen }) => {
  const { languageList, languageProficiencies } = useCandidateProfile({})
  const { t } = useTranslation()
  const isEdit = defaultValue?.language?.value
  return (
    <DynamicImportForm
      id="language-form"
      className="w-full"
      schema={schemaLanguageProfile(t)}
      defaultValue={defaultValue}
      onSubmit={onSubmitLanguage}>
      {({ formState, control, submit }) => {
        return (
          <>
            <div className="mb-3">
              <Controller
                control={control}
                name="language"
                render={({ field: { onChange, value } }) => {
                  return (
                    <FormControlItem
                      labelRequired
                      label={`${t(
                        'candidates:tabs:candidateOverview:languages:languageLabel'
                      )}`}
                      destructive={
                        formState.errors && !!formState.errors?.language
                      }
                      destructiveText={
                        formState.errors &&
                        (formState.errors?.language?.message as string)
                      }>
                      <NativeSelect
                        isClearable={false}
                        options={languageList}
                        size="sm"
                        onChange={(newValue) => {
                          const selectedOption = (newValue as ISelectOption)
                            ?.__isNew__
                            ? {
                                ...newValue,
                                supportingObj: {
                                  name: (newValue as ISelectOption)?.label
                                }
                              }
                            : newValue
                          onChange(selectedOption ? selectedOption : null)
                        }}
                        placeholder={`${t(
                          'candidates:tabs:candidateOverview:languages:languagePlaceholder'
                        )}`}
                        value={value}
                        showDropdownIndicator={false}
                        creatable={false}
                        destructive={
                          formState.errors && !!formState.errors.language
                        }
                        classNameOverride={{
                          loadingMessage: `${t('label:loading')}`,
                          noOptionsMessage: `${t('label:noOptions')}`
                        }}
                      />
                    </FormControlItem>
                  )
                }}
              />
            </div>

            <div className="mb-6">
              <Controller
                control={control}
                name="proficiency"
                render={({ field: { onChange, value } }) => (
                  <FormControlItem
                    label={`${t(
                      'candidates:tabs:candidateOverview:languages:levelLabel'
                    )}`}
                    destructive={
                      formState.errors && !!formState.errors?.proficiency
                    }
                    destructiveText={
                      formState.errors &&
                      (formState.errors?.proficiency?.message as string)
                    }>
                    <NativeSelect
                      options={languageProficiencies}
                      size="sm"
                      onChange={(newValue) => {
                        onChange(newValue ? newValue : null)
                      }}
                      isClearable
                      placeholder={`${t(
                        'candidates:tabs:candidateOverview:languages:levelPlaceholder'
                      )}`}
                      value={value}
                      destructive={
                        formState.errors && !!formState.errors.proficiency
                      }
                      classNameOverride={{
                        loadingMessage: `${t('label:loading')}`,
                        noOptionsMessage: `${t('label:noOptions')}`
                      }}
                    />
                  </FormControlItem>
                )}
              />
            </div>

            <div className="mt-3 flex justify-end">
              <Button
                className="mr-2"
                size="sm"
                onClick={() => setOpen(false)}
                label={`${t('button:cancel')}`}
                type="secondary"
              />
              <Button
                label={`${isEdit ? t('button:update') : t('button:save')}`}
                type="primary"
                size="sm"
                onClick={() => submit && submit()}
              />
            </div>
          </>
        )
      }}
    </DynamicImportForm>
  )
}

export default LanguageForm
