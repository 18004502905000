import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef
} from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
// import DiscardChangeView from '~/components/DiscardChangeView/DiscardChangeView'
import { useClassBasedTopSpace } from '~/components/Subscription/TopSpace'
import configuration from '~/configuration'
import { Button } from '~/core/ui/Button'
import { ChoiceChips } from '~/core/ui/ChoiceChips'
import { DynamicImportForm } from '~/core/ui/DynamicImportForm'
import { IFormAction } from '~/core/ui/Form'
import { FormControlItem } from '~/core/ui/FormControlItem'
import IconWrapper from '~/core/ui/IconWrapper'
import { RichEditor } from '~/core/ui/RichEditor'
import { ScrollArea } from '~/core/ui/ScrollArea'
import { TypographyText } from '~/core/ui/Text'
import { Tooltip } from '~/core/ui/Tooltip'
import { catchErrorFromGraphQL } from '~/core/utilities/catch-api-error'
import { InterviewFeedbackDetailProps } from '~/lib/features/calendar/types'
import { FEEDBACK_OPTIONS } from '~/lib/features/calendar/utilities/enum.cva'
import QueryAddSubmitFeedbackJobInterviewKits from '~/lib/features/settings/interview-kits/graphql/submit-add-submit-feedback-job-interview-kits'
import QueryUpdateSubmitFeedbackJobInterviewKits from '~/lib/features/settings/interview-kits/graphql/submit-update-submit-feedback-job-interview-kits'
import { mappingDataSubmitFeedbackJobInterviewKits } from '~/lib/features/settings/interview-kits/mapping/submit-feedback-job-interview-kits'
import schemaSubmitFeedbackJobInterviewKitsForm from '~/lib/features/settings/interview-kits/schema/submit-feedback-job-interview-kits-form'
import {
  IFormStateSubmitFeedbackInterviewKitTemplate,
  ISubmitFeedbackInterviewKitForm
} from '~/lib/features/settings/interview-kits/types'
import { useCatchAndScrollErrorForm } from '~/lib/hooks/use-catch-and-scroll-error-form'
import { useSubmitCommon } from '~/lib/hooks/use-submit-graphql-common'
import useBoundStore from '~/lib/store'
import { cn } from '~/core/ui/utils'
import { IRouterWithID } from '~/core/@types/global'
import { ICandidateApplicant } from '~/lib/features/candidates/types'
import QueryAddSubmitFeedbackJobApplicantKits from '~/lib/features/applicant-feedback/graphql/submit-add-submit-feedback-job-applicant-kits'
import QueryUpdateSubmitFeedbackJobApplicantKits from '~/lib/features/applicant-feedback/graphql/submit-update-submit-feedback-job-applicant-kits'
import { mappingDataSubmitFeedbackJobApplicantKits } from '~/lib/features/applicant-feedback/mapping/submit-feedback-job-applicant-kits'
import useApplicantFeedbackStore from '~/lib/features/applicant-feedback/store'
import useCandidateStore from '~/lib/features/candidates/store'
import { CloseButton } from '~/core/ui/CloseButton'
import { Badge, IColorBadgeType } from '~/core/ui/Badge'
import { JOB_COLOR_STAGE_NAME } from '~/lib/features/jobs/utilities/enum'
import { mappingColorByStageType } from '~/lib/features/settings/hiring-pipelines/utilities/common'
import useStaticData from 'src/hooks/data/use-static-data'
import { IStageType } from '~/lib/features/jobs/types'
import { IDotColorProps } from '~/core/ui/Dot'

interface ApplicantFeedbackFormViewProps {
  applicantShow?: ICandidateApplicant
  interviewId?: IRouterWithID
  callback?: () => void
  callbackReFetch?: () => void
  isDrawer: boolean
  onClose?: () => void
}

const InterviewFeedbackFormView = (props: ApplicantFeedbackFormViewProps) => {
  const {
    applicantShow,
    interviewId,
    callback,
    callbackReFetch,
    isDrawer,
    onClose
  } = props
  const { t, i18n } = useTranslation()
  const { trigger: triggerCreate, isLoading: isLoadingCreate } =
    useSubmitCommon(QueryAddSubmitFeedbackJobApplicantKits)
  const { trigger: triggerUpdate, isLoading: isLoadingUpdate } =
    useSubmitCommon(QueryUpdateSubmitFeedbackJobApplicantKits)
  const triggerIntervalSubmit = useRef(false)
  const { formWrapper, handleCatchErrorForm } = useCatchAndScrollErrorForm()
  const { applicantDrawer, setApplicantDrawer } = useApplicantFeedbackStore()
  const { setToast } = useBoundStore()
  const jobIkitEvaluation = applicantShow?.jobIkitEvaluation
  const defaultValue = useMemo(
    () =>
      mappingDataSubmitFeedbackJobApplicantKits({
        source: {
          id: jobIkitEvaluation?.id.toString(),
          note: jobIkitEvaluation?.note,
          overallFeedback: jobIkitEvaluation?.overallFeedback,
          status: jobIkitEvaluation?.status
        },
        keepStatus: false
      }),
    [applicantShow, applicantDrawer?.jobIkitEvalId]
  )

  const onFinishCallback = useCallback(
    async (data: ISubmitFeedbackInterviewKitForm, formAction: IFormAction) => {
      if (applicantDrawer?.jobIkitEvalId) {
        if (isLoadingUpdate) {
          return
        }

        triggerUpdate({
          ...data,
          overallFeedback: data.overallFeedback?.[0],
          applicantId: Number(applicantShow?.id),
          id: Number(applicantDrawer?.jobIkitEvalId)
        }).then((result) => {
          if (result.error) {
            return catchErrorFromGraphQL({
              error: result.error,
              page: configuration.path.settings.interviewKits,
              formAction,
              setToast
            })
          }

          const { interviewIkitFeedbacksUpdate } = result.data
          if (interviewIkitFeedbacksUpdate?.jobIkitEvaluation) {
            if (
              data.status === 'publish' &&
              triggerIntervalSubmit.current === false
            ) {
              //@ts-ignore
              setApplicantDrawer({
                ...applicantDrawer,
                openForm: false,
                jobIkitEvalId:
                  interviewIkitFeedbacksUpdate?.jobIkitEvaluation.id
              })
              setToast({
                open: true,
                type: 'success',
                title: `${t('notification:feedbackUpdated')}`
              })
              callback && callback()
            } else {
              triggerIntervalSubmit.current = false
              setApplicantDrawer({
                ...applicantDrawer,
                openForm: false,
                jobIkitEvalId:
                  interviewIkitFeedbacksUpdate?.jobIkitEvaluation?.id
              })
              callbackReFetch && callbackReFetch()

              // const mappingData = mappingDataSubmitFeedbackJobInterviewKits({
              //   source: interviewIkitFeedbacksUpdate?.jobIkitEvaluation,
              //   interviewId,
              //   keepStatus: true
              // })

              // if (mappingData) {
              //   formAction.reset()
              //   for (const [key, value] of Object.entries<any>(mappingData)) {
              //     formAction.setValue(
              //       key as keyof ISubmitFeedbackInterviewKitForm,
              //       value
              //     )
              //   }
              // }
            }
          }

          return true
        })
      } else {
        if (isLoadingCreate) {
          return
        }

        triggerCreate({
          ...data,
          applicantId: Number(applicantShow?.id),
          overallFeedback: data.overallFeedback?.[0],
          id: undefined
        }).then((result) => {
          if (result.error) {
            return catchErrorFromGraphQL({
              error: result.error,
              page: configuration.path.settings.interviewKits,
              formAction,
              setToast
            })
          }

          const { interviewIkitFeedbacksCreate } = result.data
          if (interviewIkitFeedbacksCreate?.jobIkitEvaluation) {
            if (
              data.status === 'publish' &&
              triggerIntervalSubmit.current === false
            ) {
              //@ts-ignore
              setApplicantDrawer({
                ...applicantDrawer,
                openForm: false,
                jobIkitEvalId:
                  interviewIkitFeedbacksCreate?.jobIkitEvaluation.id
              })
              setToast({
                open: true,
                type: 'success',
                title: `${t('notification:feedbackSubmitted')}`
              })
              callback && callback()
            } else {
              triggerIntervalSubmit.current = false
              setApplicantDrawer({
                ...applicantDrawer,
                openForm: false,
                jobIkitEvalId:
                  interviewIkitFeedbacksCreate?.jobIkitEvaluation.id
              })
              callbackReFetch && callbackReFetch()

              const mappingData = mappingDataSubmitFeedbackJobInterviewKits({
                source: interviewIkitFeedbacksCreate?.jobIkitEvaluation,
                interviewId,
                keepStatus: true
              })

              if (mappingData) {
                formAction.reset()
                for (const [key, value] of Object.entries<any>(mappingData)) {
                  formAction.setValue(
                    key as keyof ISubmitFeedbackInterviewKitForm,
                    value
                  )
                }
              }
            }
          }

          return true
        })
      }
    },
    [
      isLoadingUpdate,
      triggerUpdate,
      setToast,
      callback,
      callbackReFetch,
      isLoadingCreate,
      triggerCreate,
      interviewId,
      applicantDrawer,
      setApplicantDrawer,
      applicantDrawer?.jobIkitEvalId
    ]
  )

  // ------- CALC HEIGHT -------
  const height = useClassBasedTopSpace({
    34: isDrawer === false ? 'calc(100vh - 91px)' : 'calc(100vh - 2px)',
    default: 'calc(100vh - 2px)'
  })
  const containerHeightClass = useClassBasedTopSpace({
    34: isDrawer === false ? 'h-[calc(100vh_-_91px)]' : 'h-[calc(100vh_-_2px)]',
    default: 'h-[calc(100vh_-_2px)]'
  })
  // ------- END CALC HEIGHT -------

  const stageTypes = useStaticData({
    keyName: 'agency_stageTypes',
    locale: i18n.language
  })
  const getColorClassName = (stageTypes || []).filter(
    (s: IStageType) =>
      String(s.id) ===
      (applicantDrawer?.jobIkitEvalId
        ? applicantShow?.jobIkitEvaluation?.jobStages &&
          applicantShow?.jobIkitEvaluation?.jobStages?.length > 0
          ? String(applicantShow?.jobIkitEvaluation?.jobStages[0].stageTypeId)
          : String(applicantShow?.jobStage?.stageTypeId)
        : String(applicantShow?.jobStage?.stageTypeId))
  )?.[0]?.colorClassName

  const stageLabel =
    applicantShow?.jobIkitEvaluation?.jobStages &&
    applicantShow?.jobIkitEvaluation?.jobStages?.length > 0
      ? String(applicantShow?.jobIkitEvaluation?.jobStages[0].stageLabel)
      : String(applicantShow?.jobStage?.stageLabel)

  return (
    <DynamicImportForm
      isShowDebug={false}
      id="submit-feedback-form"
      defaultValue={defaultValue}
      schema={schemaSubmitFeedbackJobInterviewKitsForm(t)}
      onSubmit={onFinishCallback}
      className="h-full w-full">
      {({ formState, control, setValue, submit }) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
          const myInterval = setInterval(handleIntervalSubmit, 30000)
          return () => clearInterval(myInterval)
        }, [applicantShow])

        const handleIntervalSubmit = () => {
          if (applicantShow?.jobIkitEvaluation?.status) {
            triggerIntervalSubmit.current = true
            submit && submit()
          }
        }

        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
          handleCatchErrorForm(formState?.errors)
        }, [formState?.errors])

        return (
          <div
            className={cn(
              'relative flex min-h-full flex-col',
              containerHeightClass
            )}>
            {/* <DiscardChangeView isDirty={formState.isDirty} /> */}
            <ScrollArea
              scrollRef={formWrapper}
              rootStyle={{
                height: height
              }}>
              <div className="h-[60px] px-6 pt-4">
                <div className="flex items-center space-x-2">
                  <TypographyText className="text-base font-semibold text-gray-900">
                    {t('interview:feedback_modal:title')}
                  </TypographyText>
                  <div className="flex-none">
                    <Badge
                      color={
                        JOB_COLOR_STAGE_NAME(
                          getColorClassName
                        ) as IColorBadgeType
                      }
                      dotColor={
                        mappingColorByStageType(
                          getColorClassName
                        ) as IDotColorProps
                      }
                      size="md"
                      radius="rounded"
                      type="dotLeading">
                      {stageLabel}
                    </Badge>
                  </div>
                </div>
                <div className="mt-px">
                  <TypographyText className="text-xs font-normal text-gray-600">
                    {t(
                      'interview:application_feedback_modal:share_candidate_strengths'
                    )}
                  </TypographyText>
                </div>
                <CloseButton
                  iconClassName="w-5 h-5 absolute right-3 top-3"
                  onClick={onClose}
                />
              </div>
              <div className="space-y-8 pt-4">
                <div className="space-y-5">
                  <div className="bg-gray-50 px-6 py-2.5">
                    <TypographyText className="text-sm font-medium text-gray-900">
                      {t(
                        'interview:application_feedback_modal:overallFeedback'
                      )}
                    </TypographyText>
                  </div>
                  <div className="px-6">
                    <div className="mb-4" id="form-name-overallFeedback">
                      <Controller
                        control={control}
                        name="overallFeedback"
                        defaultValue={[]}
                        render={({ field: { onChange, value } }) => (
                          <FormControlItem
                            labelRequired
                            label={`${t('label:evaluation')}`}
                            destructive={
                              formState.errors &&
                              !!formState.errors?.overallFeedback
                            }
                            destructiveText={
                              formState.errors &&
                              (formState.errors?.overallFeedback
                                ?.message as string)
                            }>
                            <ChoiceChips
                              value={value}
                              onChange={onChange}
                              selectOption="checkbox"
                              size="sm"
                              source={FEEDBACK_OPTIONS.map((item) => ({
                                ...item,
                                label: `${t(
                                  `candidates:feedback:${item.value}`
                                )}`
                              }))}
                            />
                          </FormControlItem>
                        )}
                      />
                    </div>

                    <div className="mb-6">
                      <Controller
                        control={control}
                        name="note"
                        render={({ field: { onChange, value } }) => {
                          return (
                            <FormControlItem
                              label={`${t('label:note')}`}
                              destructive={
                                formState.errors && !!formState.errors?.note
                              }
                              destructiveText={
                                formState.errors &&
                                (formState.errors?.note?.message as string)
                              }>
                              <RichEditor
                                bubble
                                size="sm"
                                limit={50000}
                                className="w-full"
                                classNameWrapper="min-w-full w-full min-h-[96px]"
                                onChange={onChange}
                                content={value}
                                destructive={
                                  formState.errors && !!formState.errors?.note
                                }
                              />
                            </FormControlItem>
                          )
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </ScrollArea>

            <div className="flex items-center justify-between border-t border-gray-100 px-6 py-4">
              <TypographyText className="text-sm text-gray-600">
                {isLoadingCreate || isLoadingUpdate ? t('button:saving') : ''}
              </TypographyText>
              <div className="flex items-center space-x-3">
                {!applicantDrawer?.jobIkitEvalId ||
                applicantShow?.jobIkitEvaluation?.status === 'draft' ? (
                  <Button
                    size="sm"
                    type="secondary"
                    label={`${t('button:saveDraft')}`}
                    onClick={() => {
                      setValue('status', 'draft')
                      return submit && submit()
                    }}
                  />
                ) : null}
                <Button
                  isLoading={isLoadingCreate || isLoadingUpdate}
                  size="sm"
                  htmlType="button"
                  label={
                    applicantShow?.jobIkitEvaluation &&
                    applicantShow?.jobIkitEvaluation?.status !== 'draft'
                      ? `${t('button:updateFeedback')}`
                      : `${t('button:submitFeedback')}`
                  }
                  onClick={() => {
                    setValue('status', 'publish')
                    return submit && submit()
                  }}
                />
              </div>
            </div>
          </div>
        )
      }}
    </DynamicImportForm>
  )
}

export default InterviewFeedbackFormView
