import { ReactNode } from 'react'
import { Avatar } from '~/core/ui/Avatar'
import IconWrapper from '~/core/ui/IconWrapper'
import { TypographyText } from '~/core/ui/Text'
import { Tooltip, tooltipAlignProps } from '~/core/ui/Tooltip'
import { cn } from '~/core/ui/utils'

const OpenNewTabLink = ({
  label,
  link,
  showIcon = true,
  showAvatar = true,
  avatar,
  avatarColor,
  tooltip,
  tooltipAlign = 'center',
  classNameOverride,
  tooltipClassNameConfig
}: {
  label?: string
  link?: string
  showIcon?: boolean
  showAvatar?: boolean
  avatar?: string
  avatarColor?: string
  tooltip?: ReactNode
  tooltipAlign?: tooltipAlignProps
  classNameOverride?: {
    [key: string]: string
  }
  tooltipClassNameConfig?: {
    content: string
    arrow?: string
  }
}) => {
  return (
    <a
      className={cn(
        'flex cursor-pointer items-center',
        !!link ? '' : 'hover:cursor-default',
        classNameOverride?.wrapper
      )}
      {...(!!link
        ? {
            href: link,
            target: '_blank'
          }
        : {})}>
      {showAvatar && (
        <div className={cn('mr-1.5 flex-none', classNameOverride?.avatar)}>
          <Avatar size="xs" src={avatar} alt={label} color={avatarColor} />
        </div>
      )}

      <Tooltip
        align={tooltipAlign}
        content={<>{!!tooltip ? <div>{tooltip}</div> : <div>{label}</div>}</>}
        classNameConfig={tooltipClassNameConfig}>
        <TypographyText
          className={cn(
            'line-clamp-1 break-all text-sm text-gray-900',
            !!link ? 'hover:underline' : '',
            showIcon ? 'mr-1 ' : '',
            classNameOverride?.label
          )}>
          {label}
        </TypographyText>
      </Tooltip>

      {showIcon && !!link && (
        <div className={cn('', classNameOverride?.icon)}>
          <IconWrapper
            name="ExternalLink"
            size={16}
            className="flex-none text-gray-600"
          />
        </div>
      )}
    </a>
  )
}

export default OpenNewTabLink
